import Color from 'color';
import {Vector2} from '../vector2';
import {bignumber, BigNumber} from 'mathjs';
import {NirbyColor} from '../color';

/**
 * A function that linearly interpolates between two values.
 * @param a The first value.
 * @param b The second value.
 * @param t The interpolation factor.
 */
export type LerpFunction<T> = (a: T, b: T, t: number | BigNumber) => T;


function lerp(a: number | BigNumber, b: number | BigNumber, t: number | BigNumber): BigNumber {
    a = bignumber(a);
    b = bignumber(b);
    t = bignumber(t);
    return a.plus(b.minus(a).times(t));
}

function lerpNumber(a: number, b: number, t: number | BigNumber): number {
    return lerp(a, b, t).toNumber();
}

function lerpColor(a: NirbyColor, b: NirbyColor, t: number | BigNumber): NirbyColor {
    const color = Color.rgb(
        lerp(a.color.red(), b.color.red(), t).toNumber(),
        lerp(a.color.green(), b.color.green(), t).toNumber(),
        lerp(a.color.blue(), b.color.blue(), t).toNumber(),
    );
    return new NirbyColor(
        color.alpha(lerp(a.color.alpha(), b.color.alpha(), t).toNumber()),
    );
}

function lerpVector2(a: Vector2, b: Vector2, t: number | BigNumber): Vector2 {
    return new Vector2(
        lerp(a.x, b.x, t),
        lerp(a.y, b.y, t),
    );
}

export const number: LerpFunction<number> = lerpNumber;
export const color: LerpFunction<NirbyColor> = lerpColor;
export const vector2: LerpFunction<Vector2> = lerpVector2;
