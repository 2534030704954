import {
    createAnimationsSchema,
    ELEMENT_PROPERTIES_COMMON_SCHEMA,
    ELEMENT_PROPERTIES_SHARED_SCHEMA,
    ElementBase,
    extractSchemaKey,
} from '../base';
import {z} from 'zod';
import {ZodSchemaBuild} from '@nirby/store/models';

export type ElementVideo = ElementBase<'video', 'video'>

export const ELEMENT_VIDEO_SCHEMA: ZodSchemaBuild<ElementVideo> = z
    .object({
        type: z.literal('video'),
        parentId: z.string().nullable(),
        properties: z.intersection(
            ELEMENT_PROPERTIES_COMMON_SCHEMA,
            extractSchemaKey(
                ELEMENT_PROPERTIES_SHARED_SCHEMA,
                ['videoURL', 'autoplay', 'frame', 'path'],
            ),
        ),
        animations: createAnimationsSchema([]),
    }).describe('Nirby Element Video');
