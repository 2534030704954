import {Component, Input, TrackByFunction} from '@angular/core';
import {Conditional, ConditionalGroup, simplifyConditional} from '@nirby/conditionals';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {AppVariable} from '@nirby/models/editor-variables';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'nirby-conditional-editor-group',
    templateUrl: './conditional-editor-group.component.html',
    styleUrls: ['./conditional-editor-group.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ConditionalEditorGroupComponent,
            multi: true
        }
    ]
})
export class ConditionalEditorGroupComponent implements ControlValueAccessor {
    groupType: ConditionalGroup['type'] = 'and';
    items: Conditional[] = [];

    @Input() variables: AppVariable[] = [];
    icons = {
        close: faTimes,
    };

    onChange: (v: Conditional) => void = () => {
        return;
    }
    onTouched: () => void = () => {
        return;
    }
    trackByFn: TrackByFunction<Conditional> = (index: number, item: Conditional) => {
        return index;
    };

    writeValue(obj: Conditional | undefined | null): void {
        if (obj !== undefined && obj !== null && (obj.type === 'and' || obj.type === 'or')) {
            this.groupType = obj.type;
            this.items = obj.properties.conditions;
            return;
        }
        this.groupType = 'and';
        this.items = obj ? [obj] : [
            {
                type: 'equals',
                properties: {
                    target: this.variables[0]?.name,
                    value: ''
                }
            }
        ];
    }

    registerOnChange(fn: (v: Conditional) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    emitChange(): void {
        this.onChange(
            simplifyConditional({
                type: this.groupType,
                properties: {
                    conditions: this.items
                }
            })
        );
    }

    addItem() {
        this.items.push({
            type: 'equals',
            properties: {
                target: this.variables[0]?.name,
                value: ''
            }
        });
        this.emitChange();
    }

    onGroupTypeChange(type: ConditionalGroup['type']) {
        if (this.groupType === type) {
            return;
        }
        this.groupType = type;
        this.emitChange();
    }

    onItemChange(conditional: Conditional, i: number) {
        this.items[i] = conditional;
        this.emitChange();
    }

    delete(i: number) {
        this.items.splice(i, 1);
        this.emitChange();
    }
}
