/**
 * Allows to cache the result of a promise for a given time.
 */
export class CacheHash<T> {
    private cache: { [key: string]: { promise: Promise<T>, expireTime: number } } = {};

    /**
     * Constructor.
     * @param time The time in milliseconds to cache the result.
     */
    constructor(private readonly time: number = Infinity) {
    }

    /**
     * Returns the cached promise or a new promise if the cache is empty or the cache is expired.
     * @param key The key of the cache.
     * @param promiseFn The promise to cache.
     *
     * @returns - The cached promise or a new promise.
     */
    public call(key: string, promiseFn: () => Promise<T>): Promise<T> {
        const cached = this.cache[key];
        if (cached) {
            if (cached.expireTime > Date.now()) {
                return cached.promise;
            }
        }
        const promise = promiseFn();
        this.cache[key] = {
            promise,
            expireTime: Date.now() + this.time,
        };
        return promise;
    }
}
