import {ElementImage, ELEMENT_IMAGE_SCHEMA} from './image';
import {ElementText, ELEMENT_TEXT_SCHEMA} from './text';
import {ElementRectangle, ELEMENT_RECTANGLE_SCHEMA} from './rectangle';
import {ElementVideo, ELEMENT_VIDEO_SCHEMA} from './video';
import {z} from 'zod';
import { ZodSchemaBuild } from '@nirby/store/models';

export * from './image';
export * from './text';
export * from './rectangle';
export * from './video';

export type NirbyElementBlock =
    | ElementImage
    | ElementText
    | ElementRectangle
    | ElementVideo;


export const NIRBY_ELEMENT_BLOCK_SCHEMA: ZodSchemaBuild<NirbyElementBlock> = z.union([
        ELEMENT_IMAGE_SCHEMA,
        ELEMENT_TEXT_SCHEMA,
        ELEMENT_RECTANGLE_SCHEMA,
        ELEMENT_VIDEO_SCHEMA,
    ])
    .describe('Nirby Element Block');
