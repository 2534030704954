import {Component, Input} from '@angular/core';
import {ConditionalEditorBaseComponent} from '../conditional-editor-base/conditional-editor-base.component';
import {ConditionalEquals} from '@nirby/conditionals';
import {NirbyVariableNullable} from '@nirby/runtimes/state';
import {AppVariable} from '@nirby/models/editor-variables';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'nirby-conditional-editor-is',
    templateUrl: './conditional-editor-is.component.html',
    styleUrls: ['./conditional-editor-is.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ConditionalEditorIsComponent,
            multi: true
        }
    ]
})
export class ConditionalEditorIsComponent extends ConditionalEditorBaseComponent<ConditionalEquals> {
    @Input() variables: AppVariable[] = [];

    @Input() variable: string | null = null;
    value: NirbyVariableNullable = '';

    fillForm(value: ConditionalEquals): void {
        this.variable = value.properties.target;
        this.value = value.properties.value;
    }

    getCurrentValue(): ConditionalEquals {
        return {
            type: 'equals',
            properties: {
                target: this.variable ?? '',
                value: this.value
            }
        };
    }

    getDefaultValue(): ConditionalEquals {
        return {
            type: 'equals',
            properties: {
                target: this.variable ?? '',
                value: ''
            }
        };
    }

    onValueChange(value: NirbyVariableNullable): void {
        this.value = value;
        this.emitChange();
    }
}
