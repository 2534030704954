import {GetData} from './get-data';

/**
 * A resource URL.
 */
export class ResourceURL<Metadata> implements GetData<Metadata> {
    /**
     * Constructor.
     * @param url The URL of the resource.
     * @param metadata The metadata of the resource.
     */
    constructor(
        public readonly url: string,
        private readonly metadata: Metadata,
    ) {
    }

    /**
     * The URL of the resource.
     */
    get rawData(): Metadata {
        return this.metadata;
    }
}
