import {GetData} from './get-data';
import Color from 'color';

export type NirbyColorRaw =
    | `rgba(${number},${number},${number},${number})`
    | `rgb(${number},${number},${number})`
    | `#${string}`;


export interface GradientStop<Color> {
    /**
     * The color of the stop.
     */
    color: Color;
    /**
     * The position of the stop in the range [0, 1].
     */
    stop: number;
}

export interface GradientData {
    type: 'linear' | 'radial';
    stops: GradientStop<NirbyColorRaw>[];
}


export class NirbyColor implements GetData<NirbyColorRaw> {
    constructor(
        public readonly color: Color,
    ) {
    }

    public static fromRaw(raw: NirbyColorRaw): NirbyColor {
        return new NirbyColor(Color(raw));
    }

    get rawData(): NirbyColorRaw {
        return `rgba(${this.color.red()},${this.color.green()},${this.color.blue()},${this.color.alpha()})`;
    }
}


/**
 * A color gradient.
 */
export class Gradient implements GetData<GradientData> {
    /**
     * Constructor.
     * @param type The type of gradient.
     * @param stops The stops of the gradient.
     */
    constructor(
        public readonly type: 'linear' | 'radial',
        public readonly stops: GradientStop<NirbyColorRaw>[],
    ) {
    }

    /**
     * Gets the data of the gradient.
     */
    get rawData(): GradientData {
        return {
            type: this.type,
            stops: this.stops,
        };
    }

    static fromRaw(raw: GradientData): Gradient {
        return new Gradient(raw.type, raw.stops);
    }
}
