import {Pipe, PipeTransform} from '@angular/core';
import {NirbyAction} from '@nirby/models/actions';

@Pipe({
    name: 'actionType'
})
/**
 * Pipe to transform an action type code into a human-readable string.
 */
export class ActionTypePipe implements PipeTransform {
    private readonly STANDARD_CODES: Record<NirbyAction['type'], string> = {
        'answer-question': 'Answered Question',
        'card-link': 'Go to card',
        'go-to-url': 'Go to URL',
        'open-embed': 'Open Embed',
        'open-form': 'Open Form',
        'prime-pause': 'Pause',
        'track-event': 'Track event',
        'trigger-dom': 'Trigger DOM event',
        'variable-update': 'Update variable',
        'video-link': 'Go to video',
        close: 'Close',
        video: 'Open Video',
        'go-to-prime': 'Go to Prime',
        'go-back': 'Go Back',
    };

    /**
     * Transforms an action type code into a human-readable string.
     * @param value Action type code.
     *
     * @returns Human-readable string.
     */
    transform(value: NirbyAction['type'] | string): string {
        const standard = this.STANDARD_CODES[value as NirbyAction['type']] ?? null;
        if (standard) {
            return standard;
        }
        return value + ' (non-standard)';
    }
}
