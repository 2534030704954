import {
    createAnimationsSchema,
    ELEMENT_PROPERTIES_COMMON_SCHEMA,
    ELEMENT_PROPERTIES_SHARED_SCHEMA,
    ElementBase,
    extractSchemaKey,
} from '../base';
import {z} from 'zod';
import {ZodSchemaBuild} from '@nirby/store/models';

export type ElementRectangle = ElementBase<
    'rectangle',
    'rectangle'
>;


export const ELEMENT_RECTANGLE_SCHEMA: ZodSchemaBuild<ElementRectangle> = z
    .object({
        type: z.literal('rectangle'),
        parentId: z.string().nullable(),
        properties: z.intersection(
            ELEMENT_PROPERTIES_COMMON_SCHEMA,
            extractSchemaKey(
                ELEMENT_PROPERTIES_SHARED_SCHEMA,
                [
                    'fill',
                    'borderRadius',
                    'borderWidth',
                    'borderColor',
                    'path',
                    'modified',
                ],
            ),
        ),
        animations: createAnimationsSchema([
            'fill',
            'borderWidth',
            'borderColor',
        ]),
    }).describe('Nirby Element Rectangle');
