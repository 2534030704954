import {
    APP_CARD_MIGRATOR,
    DIRECTORY_MIGRATOR,
    DROPLET_MIGRATOR,
    LEGACY_PRIME_MIGRATOR,
    POP_MIGRATOR,
    PRIME_SOURCE_MIGRATOR,
    PRIME_TRACK_MIGRATOR,
    ROUTES_MIGRATOR,
    USER_MIGRATOR,
    WORKSPACE_MEMBERSHIP_MIGRATOR,
    WORKSPACE_MIGRATOR,
    WorkspaceMembership,
} from '@nirby/models/editor';
import {APP_VARIABLE_MIGRATOR} from '@nirby/models/editor-variables';
import {CF_STREAM_VIDEO_MIGRATOR, MEDIA_MIGRATOR} from '@nirby/media-models';
import {
    StripeCheckoutSession,
    StripeCustomer,
    StripeProduct,
    StripeProductPrice,
    WORKSPACE_USAGE_MIGRATOR,
} from '@nirby/models/billing';
import {LANDPOP_MIGRATOR, PUBLISHED_LANDPOP_MIGRATOR, PublishedJson, PublishedWidget} from '@nirby/models/pop';
import {CollectionKey} from '@nirby/store/base';
import {InferMigrator, Migrator} from '@nirby/store/migrator';
import {
    V1_PRIME_MIGRATOR,
    V1_SCENE_DIRECTORY_MIGRATOR,
    V1_SCENE_MIGRATOR,
    V1_TRACK_MIGRATOR,
} from '@nirby/models/editor-v1';


interface CollectionKeys {
    // root
    WORKSPACES: CollectionKey<InferMigrator<typeof WORKSPACE_MIGRATOR>>;
    USERS: CollectionKey<InferMigrator<typeof USER_MIGRATOR>>;
    // workspace
    CARDS: CollectionKey<InferMigrator<typeof APP_CARD_MIGRATOR>>;
    PRIMES: CollectionKey<InferMigrator<typeof LEGACY_PRIME_MIGRATOR>>;
    DIRECTORIES: CollectionKey<InferMigrator<typeof DIRECTORY_MIGRATOR>>;
    CF_STREAM_VIDEOS: CollectionKey<
        InferMigrator<typeof CF_STREAM_VIDEO_MIGRATOR>,
        { workspaceId: string }
    >;
    WORKSPACE_MEMBERSHIPS: CollectionKey<
        InferMigrator<typeof WORKSPACE_MEMBERSHIP_MIGRATOR>,
        { userId: string }
    >;

    PRIMES_PROJECT: CollectionKey<InferMigrator<typeof LEGACY_PRIME_MIGRATOR>>;
    PRIME_SOURCES: CollectionKey<
        InferMigrator<typeof PRIME_SOURCE_MIGRATOR>,
        { workspaceId: string; primeId: string }
    >;
    PRIME_TRACKS: CollectionKey<
        InferMigrator<typeof PRIME_TRACK_MIGRATOR>,
        { workspaceId: string; primeId: string }
    >;
    VARIABLES: CollectionKey<
        InferMigrator<typeof APP_VARIABLE_MIGRATOR>,
        { workspaceId: string, primeId: string }
    >;
    ROUTES: CollectionKey<InferMigrator<typeof ROUTES_MIGRATOR>>;
    DROPLETS: CollectionKey<InferMigrator<typeof DROPLET_MIGRATOR>>;
    MEDIA: CollectionKey<InferMigrator<typeof MEDIA_MIGRATOR>>;
    // billing
    STRIPE_CUSTOMERS: CollectionKey<StripeCustomer>;
    STRIPE_PRODUCTS: CollectionKey<StripeProduct>;
    STRIPE_CHECKOUT_SESSIONS: CollectionKey<StripeCheckoutSession>;
    STRIPE_PRICES: CollectionKey<StripeProductPrice>;
    WORKSPACE_USAGE: CollectionKey<InferMigrator<typeof WORKSPACE_USAGE_MIGRATOR>>;
    // pop
    LANDPOPS: CollectionKey<InferMigrator<typeof LANDPOP_MIGRATOR>>;
    PUBLISHED: CollectionKey<PublishedWidget>;
    PUBLISHED_POPS: CollectionKey<PublishedJson>;
    PUBLISHED_LANDPOPS: CollectionKey<InferMigrator<typeof PUBLISHED_LANDPOP_MIGRATOR>>;
    POPS: CollectionKey<InferMigrator<typeof POP_MIGRATOR>>;
    V1_SCENE: CollectionKey<InferMigrator<typeof V1_SCENE_MIGRATOR>>;
    V1_PRIME: CollectionKey<InferMigrator<typeof V1_PRIME_MIGRATOR>>;
    V1_SCENE_DIRECTORY: CollectionKey<InferMigrator<typeof V1_SCENE_DIRECTORY_MIGRATOR>>;
    V1_TRACK: CollectionKey<InferMigrator<typeof V1_TRACK_MIGRATOR>>;
}

export const COLLECTION_KEYS: CollectionKeys = {
    // root
    WORKSPACES: CollectionKey.root(
        'widgets',
        WORKSPACE_MIGRATOR,
        'workspaces',
    ),
    USERS: CollectionKey.remote('users', USER_MIGRATOR),
    // workspace
    CARDS: CollectionKey.workspace('cards', APP_CARD_MIGRATOR),
    PRIMES: CollectionKey.workspace('primes', LEGACY_PRIME_MIGRATOR),
    DIRECTORIES: CollectionKey.workspace('directories', DIRECTORY_MIGRATOR),
    CF_STREAM_VIDEOS: CollectionKey.workspace(
        'cloudflare-stream-videos',
        CF_STREAM_VIDEO_MIGRATOR,
        null,
        (query, ctx: { workspaceId: string }) => {
            return query.where('workspaceId', '==', ctx.workspaceId);
        },
    ),
    WORKSPACE_MEMBERSHIPS: CollectionKey.workspace<WorkspaceMembership, { userId: string }>(
        'memberships',
        WORKSPACE_MEMBERSHIP_MIGRATOR,
        null,
        (query, ctx) => {
            return query.where('userId', '==', ctx.userId);
        },
        undefined,
        true,
        false,
    ),
    PRIMES_PROJECT: CollectionKey.workspace(
        'primes',
        LEGACY_PRIME_MIGRATOR,
    ),
    PRIME_SOURCES: CollectionKey.workspace(
        'prime-sources',
        PRIME_SOURCE_MIGRATOR,
        null,
        (
            query,
            ctx: { workspaceId: string, primeId: string },
            db,
        ) =>
            query
                .where(
                    'usedBy',
                    '==',
                    db.collection(
                        COLLECTION_KEYS.WORKSPACES.doc(ctx.workspaceId),
                        COLLECTION_KEYS.PRIMES,
                    ).ref(ctx.primeId),
                ),
    ),
    PRIME_TRACKS: CollectionKey.workspace(
        'prime-tracks',
        PRIME_TRACK_MIGRATOR,
        null,
        (
            query,
            ctx: { workspaceId: string, primeId: string },
            db,
        ) =>
            query.where(
                'usedBy',
                '==',
                db.collection(
                    COLLECTION_KEYS.WORKSPACES.doc(ctx.workspaceId),
                    COLLECTION_KEYS.PRIMES,
                ).ref(ctx.primeId),
            ),
    ),
    VARIABLES: CollectionKey.workspace(
        'variables',
        APP_VARIABLE_MIGRATOR,
        null,
        (
            query,
            ctx: { workspaceId: string, primeId: string },
            db,
        ) =>
            query.where(
                'usedBy',
                '==',
                db.collection(
                    COLLECTION_KEYS.WORKSPACES.doc(ctx.workspaceId),
                    COLLECTION_KEYS.PRIMES,
                ).ref(ctx.primeId),
            ),
    ),
    // remote
    WORKSPACE_USAGE: CollectionKey.remote('usage', WORKSPACE_USAGE_MIGRATOR),
    STRIPE_PRODUCTS: CollectionKey.remote(
        'stripe-products',
        Migrator.any<StripeProduct>(),
    ),
    STRIPE_CUSTOMERS: CollectionKey.remote(
        'stripe-customers',
        Migrator.any<StripeCustomer>(),
    ),
    STRIPE_CHECKOUT_SESSIONS: CollectionKey.remote(
        'checkout_sessions',
        Migrator.any<StripeCheckoutSession>(),
    ),
    STRIPE_PRICES: CollectionKey.remote(
        'prices',
        Migrator.any<StripeProductPrice>(),
    ),
    ROUTES: CollectionKey.remote('routes', ROUTES_MIGRATOR),
    MEDIA: CollectionKey.remote('media', MEDIA_MIGRATOR),
    LANDPOPS: CollectionKey.remote('landpops', LANDPOP_MIGRATOR),
    DROPLETS: CollectionKey.remote('droplets', DROPLET_MIGRATOR),
    PUBLISHED: CollectionKey.remote(
        'published',
        Migrator.any<PublishedWidget>(),
    ),
    PUBLISHED_POPS: CollectionKey.remote(
        'campaigns',
        Migrator.any<PublishedJson>(),
    ),
    PUBLISHED_LANDPOPS: CollectionKey.remote(
        'publishedLandpops',
        PUBLISHED_LANDPOP_MIGRATOR,
    ),
    POPS: CollectionKey.remote('campaigns', POP_MIGRATOR),
    V1_SCENE: CollectionKey.remote('v1-prime-scenes', V1_SCENE_MIGRATOR),
    V1_SCENE_DIRECTORY: CollectionKey.remote('v1-prime-scene-directories', V1_SCENE_DIRECTORY_MIGRATOR),
    V1_PRIME: CollectionKey.remote('v1-primes', V1_PRIME_MIGRATOR),
    V1_TRACK: CollectionKey.remote('v1-prime-tracks', V1_TRACK_MIGRATOR),
};
