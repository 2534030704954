<nirby-toolbar></nirby-toolbar>
<div class="app-container m-3">
    <p class="text-light">
        Select a workspace
    </p>
    <div class="d-flex flex-column justify-content-center align-items-stretch w-100 h-100">
        <ng-container *ngIf="workspaces$ | async as workspaces; else loading">
            <div class="workspace-list">
                <a
                    *ngFor="let workspace of workspaces"
                    class="workspace-card d-flex flex-column justify-content-center align-items-center"
                    [routerLink]="['/workspaces', workspace.data.workspaceId, 'plans']"
                >
                    <span class="workspace-title">{{workspace.data.workspaceName}}</span>
                    <span class="workspace-subtitle">{{workspace.creationTime.toJSDate() | date}}</span>
                </a>
            </div>
            <div class="d-flex flex-column align-items-center" *ngIf="workspaces.length === 0">
                <p class="text-light text-center">
                    You don't have any workspaces yet.
                </p>
                <button class="btn btn-primary" (click)="createWorkspace()" [disabled]="creating">
                    Create a workspace
                </button>
            </div>
        </ng-container>
        <ng-template #loading>
            <div class="w-100 d-flex flex-column justify-content-center align-items-center">
                <p class="text-light">
                    Loading...
                </p>
            </div>
        </ng-template>
    </div>
</div>
