import {Component, EventEmitter, Input, Output, TrackByFunction} from '@angular/core';

import {filter, switchMap} from 'rxjs/operators';
import {faPlus, faQuestionCircle, faTimes} from '@fortawesome/free-solid-svg-icons';
import {BehaviorSubject} from 'rxjs';
import {BlockController} from '../../services/block-controller';
import {ActionTrigger, factoryActions} from '@nirby/models/nirby-player';
import {NirbyProductType} from '@nirby/analytics-typings';
import {NirbyDocument} from '@nirby/store/base';
import {AnyPrimeSource, AppCard} from '@nirby/models/editor';
import {AppVariable} from '@nirby/models/editor-variables';
import { GoToURLAction, NirbyAction } from '@nirby/models/actions';

@Component({
    selector: 'nirby-action-generic-editor',
    templateUrl: './action-generic-editor.component.html',
    styleUrls: ['./action-generic-editor.component.scss'],
})
/**
 * Component to edit an action in a {@link BlockController}
 */
export class ActionGenericEditorComponent<TMeta = unknown> {
    public readonly closeIcon = faTimes;
    public readonly addIcon = faPlus;
    public readonly iconQuestion = faQuestionCircle;

    private readonly controllerSubject =
        new BehaviorSubject<BlockController<TMeta> | null>(null);
    public readonly controller$ = this.controllerSubject.pipe(
        filter((controller): controller is BlockController<TMeta> => !!controller),
    );

    @Input() productType: NirbyProductType | null = null;
    @Input() cardSiblings: NirbyDocument<AppCard>[] = [];
    @Input() availableVideos: NirbyDocument<AnyPrimeSource>[] = [];
    @Input() variables: AppVariable[] = [];
    @Output() private closeRequest = new EventEmitter<void>();

    /**
     * The block controller whose action is being edited
     * @param value The block controller whose action is being edited
     */
    @Input() set controller(value: BlockController<TMeta> | null) {
        this.controllerSubject.next(value);
    }

    /**
     * The block controller whose action is being edited
     */
    get controller(): BlockController<TMeta> | null {
        return this.controllerSubject.value;
    }

    public readonly actions$ = this.controller$.pipe(
        switchMap((c) => c.actions.watchTrigger('click')),
    );
    hasEndingAction$ = this.controller$.pipe(
        switchMap((c) => c.actions.watchHasEndingAction$('click')),
    );

    icons = {
        add: faPlus,
    };
    /**
     * The track by function for the actions
     * @param index The index of the action
     * @param action The action
     *
     * @returns The action's id
     */
    trackByActionId: TrackByFunction<NirbyAction> = (index, action) => action.id;

    /**
     * Adds action to the given action trigger.
     * @param trigger The action trigger to add the action to
     * @param index The index of the action to add. If undefined, the action will be added to the end of the trigger's
     * actions
     */
    public addActionToEvent(trigger: ActionTrigger, index?: number): void {
        const controller = this.controller;
        if (!controller) {
            return;
        }
        controller.actions.addTo(
            trigger,
            factoryActions<GoToURLAction>('go-to-url'),
            index,
        );
    }

    /**
     * Remove the action at the given index from the given action trigger
     * @param index The index of the action to remove
     * @param trigger The action trigger to remove the action from
     */
    public removeAction(index: number, trigger: ActionTrigger): void {
        const controller = this.controller;
        if (!controller) {
            return;
        }
        controller.actions.deleteAt(trigger, index);
    }

    /**
     * Requests the editor to be closed
     */
    close(): void {
        this.closeRequest.emit();
    }
}
