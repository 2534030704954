import {Point} from 'pixi.js';
import {acos, atan2, bignumber, BigNumber, cos, max, min, sin, sqrt} from 'mathjs';
import {divide, minus, multiply, plus} from './operations';
import {GetData} from './get-data';

export type AngleDirection = 'shortest' | 'clockwise' | 'counter-clockwise';

export interface Vector2Data {
    x: number;
    y: number;
}


/**
 * A two-dimensional utils.
 */
export class Vector2 implements GetData<Vector2Data> {
    public static readonly zero = new Vector2(0, 0);
    public static readonly one = new Vector2(1, 1);

    private calculatedPoint: {
        readonly x: number,
        readonly y: number,
    } | null = null;

    /**
     * The slope of the utils.
     * @private
     */
    public get slope(): BigNumber {
        return divide(this.y, this.x);
    }

    public get point(): {
        readonly x: number,
        readonly y: number,
    } {
        if (this.calculatedPoint === null) {
            this.calculatedPoint = {
                x: this.x.toNumber(),
                y: this.y.toNumber(),
            };
        }
        return this.calculatedPoint;
    }

    public readonly x: BigNumber;
    public readonly y: BigNumber;

    /**
     * Constructor.
     * @param x The x-coordinate of the utils.
     * @param y The y-coordinate of the utils.
     */
    constructor(
        x: number | BigNumber,
        y: number | BigNumber,
    ) {
        this.x = bignumber(x);
        this.y = bignumber(y);
    }

    /**
     * Relative to another position.
     * @param position The position to be relative to.
     *
     * @returns The relative position.
     */
    relativeTo(position: Vector2): Vector2 {
        return new Vector2(
            this.x.minus(position.x),
            this.y.minus(position.y),
        );
    }

    add(deltaX: number | BigNumber, deltaY: number | BigNumber): Vector2;
    add(delta: Vector2): Vector2;

    add(deltaX: number | BigNumber | Vector2, deltaY?: number | BigNumber): Vector2 {
        if (deltaX instanceof Vector2) {
            return this.add(deltaX.x, deltaX.y);
        }
        return new Vector2(
            plus(this.x, deltaX),
            plus(this.y, (deltaY ?? 0)),
        );
    }

    subtract(deltaX: number | BigNumber, deltaY: number | BigNumber): Vector2;
    subtract(delta: Vector2): Vector2;

    subtract(deltaX: number | BigNumber | Vector2, deltaY?: number | BigNumber): Vector2 {
        if (deltaX instanceof Vector2) {
            return this.subtract(deltaX.x, deltaX.y);
        }
        return new Vector2(
            minus(this.x, deltaX),
            minus(this.y, (deltaY ?? 0)),
        );
    }

    scale(factor: number | BigNumber | Vector2): Vector2 {
        if (factor instanceof Vector2) {
            return new Vector2(
                multiply(this.x, factor.x),
                multiply(this.y, factor.y),
            );
        }
        return new Vector2(
            multiply(this.x, factor),
            multiply(this.y, factor),
        );
    }

    get magnitude(): BigNumber {
        return sqrt(
            multiply(this.x, this.x).plus(multiply(this.y, this.y)),
        );
    }

    get normalized(): Vector2 {
        return this.scale(divide(1, this.magnitude));
    }

    /**
     * Calculates the dot product of this utils and another.
     * @param other The other utils.
     *
     * @returns The dot product.
     */
    dot(other: Vector2): BigNumber {
        return this.x.times(other.x).plus(this.y.times(other.y));
    }

    /**
     * Calculates the angle between this utils and another in radians.
     * @param other The other utils.
     * @param direction The direction to which the angle is measured. Defaults to 'shortest'. If 'shortest', the
     * shortest angle between the two vectors is returned. If 'clockwise', the angle between the two vectors is
     * measured clockwise. If 'counter-clockwise', the angle between the two vectors is measured counter-clockwise.
     * If 'clockwise' or 'counter-clockwise', the angle is always positive.
     *
     * @returns The angle between the two vectors.
     */
    angleTo(other: Vector2, direction: AngleDirection = 'shortest'): number {
        const a = this.normalized;
        const b = other.normalized;

        if (direction === 'shortest') {
            const dot = a.dot(b);
            return acos(dot).toNumber();
        } else if (direction === 'clockwise') {
            const dot = a.dot(b);
            const det = bignumber(a.x.times(b.y).minus(a.y.times(b.x)));
            return atan2(det.toNumber(), dot.toNumber());
        } else {
            const dot = a.dot(b);
            const det = bignumber(a.x.times(b.y).minus(a.y.times(b.x)));
            return atan2(-det.toNumber(), dot.toNumber());
        }
    }

    /**
     * Calculates the angle between this utils and another in degrees.
     * @param other The other utils.
     *
     * @returns The angle between the two vectors.
     */
    angleToDegrees(other: Vector2): number {
        return this.angleTo(other) * 180 / Math.PI;
    }

    /**
     * Creates a new utils from a {@link Point}.
     * @param point The point.
     *
     * @returns The utils.
     */
    static fromPoint(point: Point | { x: number, y: number }): Vector2 {
        return new Vector2(point.x, point.y);
    }

    /**
     * Calculates the distance to another utils.
     * @param other The other utils.
     *
     * @returns The distance.
     */
    distanceTo(other: Vector2): BigNumber {
        return this.subtract(other).magnitude;
    }

    /**
     * Compares this utils to another.
     * @param other The other utils.
     * @param epsilon The maximum difference between the two vectors for them to be considered equal.
     *
     * @returns Whether the two vectors are equal.
     */
    equals(other: Vector2, epsilon = 0): boolean {
        return this.distanceTo(other).lessThanOrEqualTo(epsilon);
    }

    /**
     * Divides this utils by another.
     * @param size The utils to divide by.
     *
     * @returns The divided utils.
     */
    divide(size: Vector2 | number): Vector2 {
        if (size instanceof Vector2) {
            return new Vector2(
                this.x.dividedBy(size.x),
                this.y.dividedBy(size.y),
            );
        }
        return this.divide(new Vector2(size, size));
    }

    /**
     * Gets the utils that represents the top-left corner of the box formed by this utils and another.
     * @param other The other utils.
     *
     * @returns The top-left corner.
     */
    min(other: Vector2): Vector2 {
        return new Vector2(
            min(this.x, other.x),
            min(this.y, other.y),
        );
    }

    /**
     * Gets the utils that represents the bottom-right corner of the box formed by this utils and another.
     * @param other The other utils.
     *
     * @returns The bottom-right corner.
     */
    max(other: Vector2): Vector2 {
        return new Vector2(
            max(this.x, other.x),
            max(this.y, other.y),
        );
    }

    /**
     * Gets this utils as a string in the format '(x, y)'.
     * @param precision The number of decimal places to round to. Defaults to 2.
     *
     * @returns The string.
     */
    toString(precision = 2): string {
        return `(${this.x.toFixed(precision)}, ${this.y.toFixed(precision)})`;
    }

    rotateAround(pivot: Vector2, rotation: number): Vector2 {
        const relative = this.relativeTo(pivot);
        const rotated = new Vector2(
            relative.x.times(cos(rotation)).minus(relative.y.times(sin(rotation))),
            relative.x.times(sin(rotation)).plus(relative.y.times(cos(rotation))),
        );
        return rotated.add(pivot);
    }

    /**
     * Calculates the slope of the utils that connects this utils to another.
     * @param other The other utils.
     *
     * @returns The slope.
     */
    slopeTo(other: Vector2): BigNumber {
        const toOther = other.subtract(this);
        return toOther.slope;
    }

    /**
     * Creates a new utils where x and y are the absolute values of this utils's x and y.
     *
     * @returns The absolute utils.
     */
    abs(): Vector2 {
        return new Vector2(
            this.x.abs(),
            this.y.abs(),
        );
    }

    /**
     * Gets the raw data of this vector.
     */
    get rawData(): Vector2Data {
        return {
            x: this.point.x,
            y: this.point.y,
        };
    }

    /**
     * Preloads the point data.
     *
     * @returns This vector.
     */
    preload(): this {
        this.point;
        return this;
    }
}
