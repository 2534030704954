import {SCENE_BACKGROUND_SCHEMA, SceneBackground} from './scene-background';
import {NirbyDocumentReference} from '@nirby/store/base';
import {z} from 'zod';
import {buildUploadedReferenceSchema} from '@nirby/store/proxy';
import {ZodSchemaBuild} from '@nirby/store/models';
import {Migrator} from '@nirby/store/migrator';
import {V1_PRIME_MIGRATOR, V1Prime} from '../v1-prime';

export interface SceneTimestamp {
    sceneRef: NirbyDocumentReference<Scene> | null;
    timeMs: number;
}

export const SCENE_TIMESTAMP_SCHEMA: ZodSchemaBuild<SceneTimestamp> = z.object(
    {
        sceneRef: buildUploadedReferenceSchema(() => {
            return V1_SCENE_MIGRATOR;
        }).nullable(),
        timeMs: z.number(),
    });

/**
 * A scene is one of the slides of a Prime experience.
 */
export interface Scene {
    /**
     * The name of the scene.
     */
    name: string;
    /**
     * The background of the scene.
     */
    background: SceneBackground;
    /**
     * The ratio of the scene (e.g. 16:9).
     */
    ratio: {
        x: number;
        y: number;
    };
    /**
     * The duration of the scene in milliseconds.
     */
    durationMs: number;
    /**
     * The depth of the scene.
     */
    depth: number,
    /**
     * The order of the scene in the Prime.
     */
    order: number;
    usedBy: NirbyDocumentReference<V1Prime> | null;
    nextScene: SceneTimestamp | null;
}

export const V1_SCENE_SCHEMA: ZodSchemaBuild<Scene> = z.object(
    {
        name: z.string().default('untitled scene'),
        background: SCENE_BACKGROUND_SCHEMA.default({
            type: 'solid',
            properties: {
                color: '#00000000',
            },
        }),
        ratio: z.object({x: z.number(), y: z.number()}).default({x: 16, y: 9}),
        durationMs: z.number().default(0),
        depth: z.number().default(0),
        order: z.number().default(0),
        usedBy: buildUploadedReferenceSchema(() => {
            return V1_PRIME_MIGRATOR;
        }).default(null),
        nextScene: SCENE_TIMESTAMP_SCHEMA.nullable().default(null),
    });

export const V1_SCENE_MIGRATOR: Migrator<Scene> = new Migrator(V1_SCENE_SCHEMA, () => (
    {
        name: 'untitled scene',
        background: {
            type: 'solid',
            properties: {
                color: '#00000000',
            },
        },
        ratio: {x: 100, y: 100},
        durationMs: 10 * 1000,
        depth: 0,
        order: 0,
        usedBy: null,
        nextScene: null,
    }
));
