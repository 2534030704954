export function linear(t: number): number {
    return t;
}

export function quadratic(t: number): number {
    return t * t;
}

export function cubic(t: number): number {
    return t * t * t;
}

export function quartic(t: number): number {
    return t * t * t * t;
}

export function quintic(t: number): number {
    return t * t * t * t * t;
}

export function sigmoid(t: number): number {
    return 1 / (1 + Math.exp(-t));
}

export function sine(t: number): number {
    return Math.sin(t * Math.PI / 2);
}

export function circular(t: number): number {
    return 1 - Math.sqrt(1 - t * t);
}

export function exp(t: number): number {
    return Math.pow(2, 10 * (t - 1));
}

