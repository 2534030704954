import {LerpableType, SegmentedFunction, SegmentedFunctionDescription} from '../animation';
import {
    ImageShapeProperties,
    MetaMetadata,
    RectangleShapeProperties,
    StageObjectMetadata,
    TextShapeProperties,
    VectorShapeProperties,
    VideoShapeProperties,
} from '.';


export interface ShapeProperties {
    vector: VectorShapeProperties;
    image: ImageShapeProperties;
    video: VideoShapeProperties;
    text: TextShapeProperties;
    rectangle: RectangleShapeProperties;
}

export const SHAPE_TYPES = new Set<keyof ShapeProperties>([
    'vector',
    'image',
    'video',
    'text',
    'rectangle',
]);

type KeysOfValue<T, V> = {
    [Key in keyof T]: T[Key] extends V ? Key : never;
}[keyof T];

export type ShapeAnimationsDescription<Properties extends object> = {
    [Key in KeysOfValue<Properties, LerpableType>]?: Properties[Key] extends LerpableType ?
        SegmentedFunctionDescription<Properties[Key]> :
        never;
}

export class AnimatedProperty<Properties extends object, Key extends KeysOfValue<Properties, LerpableType> = KeysOfValue<Properties, LerpableType>> {
    constructor(
        public readonly key: Key,
        public readonly animation: Properties[Key] extends LerpableType ?
            SegmentedFunction<Properties[Key]> :
            never,
        private readonly offsetMs = 0,
    ) {
    }

    public apply(currentTimeMs: number): Properties[Key] {
        return this.animation.calc(currentTimeMs - this.offsetMs);
    }

    asAnimations(): ShapeAnimationsDescription<Properties> {
        return {
            [this.key]: this.animation.describe() as unknown as ShapeAnimationsDescription<Properties>[Key],
        } as ShapeAnimationsDescription<Properties>;
    }

    static fromProperty<Properties extends object, Key extends KeysOfValue<Properties, LerpableType>>(
        properties: ShapeAnimationsDescription<Properties>,
        key: Key,
        offsetMs = 0,
    ): AnimatedProperty<Properties, Key> {
        const property = properties[key] as Properties[Key] extends LerpableType ? SegmentedFunctionDescription<Properties[Key]> : never;
        return new AnimatedProperty<Properties, Key>(
            key,
            SegmentedFunction.fromDescription(property) as Properties[Key] extends LerpableType ? SegmentedFunction<Properties[Key]> : never,
            offsetMs,
        );
    }
}


export class ShapeAnimations<Properties extends object> {
    private constructor(
        private readonly properties: AnimatedProperty<Properties>[] = [],
        public readonly offsetMs = 0,
    ) {
    }

    static fromDescription<Properties extends object>(
        description: ShapeAnimationsDescription<Properties>,
        offsetMs = 0,
    ): ShapeAnimations<Properties> {
        const properties: AnimatedProperty<Properties>[] = [];
        for (const key in description) {
            properties.push(AnimatedProperty.fromProperty(
                description,
                key as KeysOfValue<Properties, LerpableType>,
                0,
            ));
        }
        return new ShapeAnimations<Properties>(properties, offsetMs);
    }

    describe(): ShapeAnimationsDescription<Properties> {
        let description: ShapeAnimationsDescription<Properties> = {};
        for (const property of this.properties) {
            description = {
                ...description,
                ...property.asAnimations(),
            };
        }
        return description;
    }

    apply(currentTime: number): Partial<Properties> {
        const properties: Partial<Properties> = {};
        for (const property of this.properties) {
            properties[property.key] = property.apply(currentTime - this.offsetMs);
        }
        return properties;
    }

    applyPerProperty(currentTime: number): { k: keyof Properties, v: Properties[keyof Properties] }[] {
        const properties: { k: keyof Properties, v: Properties[keyof Properties] }[] = [];
        for (const property of this.properties) {
            properties.push({
                k: property.key,
                v: property.apply(currentTime - this.offsetMs),
            });
        }
        return properties;
    }

    empty(): boolean {
        return this.properties.length === 0;
    }

    static empty<Properties extends object>() {
        return new ShapeAnimations<Properties>();
    }
}

export type NirbyBoardShape<
    ShapeType extends keyof ShapeProperties = keyof ShapeProperties,
    M extends MetaMetadata = MetaMetadata
> = {
    [ShapeType in keyof ShapeProperties]: {
        type: ShapeType;
        properties: StageObjectMetadata<ShapeProperties[ShapeType]>;
        metadata: M['metadata'];
        animations: ShapeAnimations<StageObjectMetadata<ShapeProperties[ShapeType]>>;
    };
}[ShapeType];

export type AnyNirbyBoardShape<M extends MetaMetadata = MetaMetadata> = NirbyBoardShape<keyof ShapeProperties, M>;
