export interface GetData<RawData> {
    get rawData(): RawData;
}

export type GetDataRaw<T> = T extends GetData<infer D> ? D : T;

export type GetDataFactory<T> = {
    fromRaw(raw: GetDataRaw<T>): T;
};

export function isGetData<T>(data: unknown): data is GetData<T> {
    return typeof (data as GetData<GetDataRaw<T>>).rawData !== 'undefined';
}

export function getDataRaw<T>(data: T): GetDataRaw<T> {
    if (isGetData<T>(data)) {
        return data.rawData as GetDataRaw<T>;
    }
    return data as GetDataRaw<T>;
}
