<button
    class="btn btn-tag"
    (click)="toggle(); $event.stopPropagation()"
    [disabled]="disabled"
    *ngIf="selectedOption$ | async as option"
    [ngbTooltip]="option.label"
    container="body"
    [ngStyle]="{ 'background-color': option.color, color: option.fontColor }"
>
    <fa-icon *ngIf="option.icon" [icon]="option.icon"></fa-icon>
    <span *ngIf="option.label">{{ option.label }}</span>
</button>
