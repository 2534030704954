import {Injectable} from '@angular/core';
import {PrimesService} from './primes.service';
import {PopService} from './pop.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {NirbyProductType} from '@nirby/analytics-typings';

interface ProductDescription {
    id: string;
    type: NirbyProductType;
    name: string;
}

@Injectable({
    providedIn: 'root',
})
/**
 * Service to get Primes and Pops
 */
export class ProductsService {
    /**
     * Constructor.
     * @param primes Primes service.
     * @param pops Pop service.
     */
    constructor(
        private readonly primes: PrimesService,
        private readonly pops: PopService,
    ) {}

    products: Record<NirbyProductType, Observable<ProductDescription[]>> = {
        prime: this.primes.workspacePrime$.pipe(
            map((primes) =>
                primes.map<ProductDescription>((prime) => ({
                    id: prime.id,
                    type: 'prime',
                    name: prime.data.name,
                })),
            ),
        ),
        pop: this.pops.popSiblings$.pipe(
            map((pops) =>
                pops.map<ProductDescription>((prime) => ({
                    id: prime.id,
                    type: 'pop',
                    name: prime.data.title,
                })),
            ),
        ),
    };
}
