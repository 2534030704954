import {ElementBase} from './base';
import {NIRBY_ELEMENT_BLOCK_SCHEMA, NirbyElementBlock} from './blocks';
import {z} from 'zod';
import {ZodSchemaBuild} from '@nirby/store/models';

export type NirbyElementGroup = ElementBase<'group', never>;

export type AnyNirbyElement =
    | NirbyElementGroup
    | NirbyElementBlock;

export type NirbyElement<Type extends AnyNirbyElement['type'] = AnyNirbyElement['type']> = AnyNirbyElement & {
    type: Type
};

export const NIRBY_ELEMENT_GROUP_SCHEMA: ZodSchemaBuild<NirbyElementGroup> = z
    .object({
        type: z.enum(['group']),
        parentId: z.string().nullable(),
        properties: z.never(),
        animations: z.never(),
    })
    .describe('Nirby Element Group');

export const NIRBY_ELEMENT_SCHEMA: ZodSchemaBuild<NirbyElement> = z.union([
    NIRBY_ELEMENT_GROUP_SCHEMA,
    NIRBY_ELEMENT_BLOCK_SCHEMA,
])
    .describe('Nirby Element');

export * from './blocks';
export * from './base';
export * from './types';
