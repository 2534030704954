import {NIRBY_TRACK_ELEMENT_SCHEMA, NirbyTrackElement} from './element';
import {NIRBY_TRACK_ACTION_SCHEMA, NirbyTrackAction} from './action';
import {ZodSchemaBuild} from '@nirby/store/models';
import {z} from 'zod';
import {Migrator} from '@nirby/store/migrator';
import {Vector2, VectorPath} from '@nirby/board-structures';

export * from './base';
export * from './action';
export * from './element';

export type NirbyTrack =
    NirbyTrackElement |
    NirbyTrackAction;

export const NIRBY_TRACK_SCHEMA: ZodSchemaBuild<NirbyTrack> = z.union([
    NIRBY_TRACK_ELEMENT_SCHEMA,
    NIRBY_TRACK_ACTION_SCHEMA,
])
    .describe('Nirby TRACK');

export const V1_TRACK_MIGRATOR = new Migrator<NirbyTrack>(NIRBY_TRACK_SCHEMA, () => ({
    type: 'bounded',
    contentType: 'element',
    name: '',
    sceneRef: null,
    order: 0,
    usedBy: null,
    properties: {
        element: {
            parentId: null,
            properties: {
                position: Vector2.zero.rawData,
                size: new Vector2(320, 240).rawData,
                opacity: 1,
                borderRadius: {
                    topLeft: 0,
                    topRight: 0,
                    bottomLeft: 0,
                    bottomRight: 0,
                },
                rotation: 0,
                path: VectorPath.rectangle(1, 1).rawData,
                fill: 'rgba(255,255,255,1)',
                borderWidth: 0,
                borderColor: 'rgba(0,0,0,1)',
                modified: false,
            },
            type: 'rectangle',
            animations: {},
        },
        startTimeMs: 0,
        endTimeMs: 1,
    },
}));

/**
 type: 'keyframe',
 contentType: 'action',
 name: '',
 sceneRef: null,
 order: 0,
 usedBy: null,
 properties: {
        action: null,
        startTimeMs: 0,
    },
 */
