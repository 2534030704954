import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {AuthenticationService, WorkspaceService} from '@nirby/shared/database';
import {Logger} from '@nirby/logger';

@Injectable({
    providedIn: 'root',
})
export class WidgetsGuard implements CanActivate {
    constructor(
        private router: Router,
        private auth: AuthenticationService,
        private widget: WorkspaceService,
    ) {
    }

    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Promise<true | UrlTree> {
        const workspaceId = route.params['widgetId'];
        if (!workspaceId) {
            Logger.warn('No workspaceId provided');
            return this.router.parseUrl('/workspaces');
        }
        const user = await this.auth.getAuthenticatedUser();
        if (!user) {
            return this.router.parseUrl('/auth/login');
        }
        const hasThisWorkspace = await this.widget.belongsToUser(user.id, workspaceId);
        if (!hasThisWorkspace) {
            return this.router.parseUrl('/workspaces');
        }
        return true;
    }
}
