import {NirbyProductType} from '../typings';

export type ProductVersion = 'legacy' | 'v1';

export const PRODUCT_TYPES: NirbyProductType[] = ['pop', 'prime'];

export const PRODUCT_COLLECTIONS: Record<NirbyProductType, Record<ProductVersion, string>> = {
    pop: {
        legacy: 'campaigns',
        v1: 'campaigns',
    },
    prime: {
        legacy: 'primes',
        v1: 'v1-primes',
    },
};
