import {
    createAnimationsSchema,
    ELEMENT_PROPERTIES_COMMON_SCHEMA,
    ELEMENT_PROPERTIES_SHARED_SCHEMA,
    ElementBase,
    extractSchemaKey,
} from '../base';
import {z} from 'zod';
import {ZodSchemaBuild} from '@nirby/store/models';

export type ElementText = ElementBase<'text', 'text'>

export const ELEMENT_TEXT_SCHEMA: ZodSchemaBuild<ElementText> = z
    .object({
        type: z.literal('text'),
        parentId: z.string().nullable(),
        properties: z.intersection(
            ELEMENT_PROPERTIES_COMMON_SCHEMA,
            extractSchemaKey(
                ELEMENT_PROPERTIES_SHARED_SCHEMA,
                [
                    'text',
                    'textVerticalAlignment',
                    'textAlignment',
                    'fontSize',
                    'fill',
                    'fontFamily',
                    'textLineHeight',
                ],
            ),
        ),
        animations: createAnimationsSchema([
            'fontSize',
            'fill',
            'textLineHeight',
        ]),
    }).describe('Nirby Element Text');
