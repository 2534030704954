import {bignumber, BigNumber} from 'mathjs';

export function minus(a: number | BigNumber, b: number | BigNumber): BigNumber {
    return bignumber(a).minus(bignumber(b));
}

export function plus(a: number | BigNumber, b: number | BigNumber): BigNumber {
    return bignumber(a).plus(bignumber(b));
}

export function multiply(a: number | BigNumber, b: number | BigNumber): BigNumber {
    return bignumber(a).times(bignumber(b));
}

export function divide(a: number | BigNumber, b: number | BigNumber): BigNumber {
    return bignumber(a).div(bignumber(b));
}

export function sign(a: number | BigNumber): -1 | 0 | 1 {
    a = bignumber(a);
    if (a.isZero()) {
        return 0;
    }
    return a.isNegative() ? -1 : 1;
}
