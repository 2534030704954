import { Injectable } from '@angular/core';
import { NirbyTrack } from '@nirby/models/editor-v1';
import { Database } from '@nirby/ngutils';
import { NirbyDocument } from '@nirby/store/base';
import { COLLECTION_KEYS } from '@nirby/store/collections';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/***/
export class V1PrimeTracksService {
    /***/
    constructor(
        private db: Database,
    ) {}

    /**
     * Get all the tracks from a Prime
     * @param workspaceId The workspace ID
     * @param primeId The Prime ID
     *
     * @returns - An observable that resolves to the tracks
     */
    getTracksForPrime(
        workspaceId: string,
        primeId: string,
    ): Promise<NirbyDocument<NirbyTrack>[]> {
        const primeDoc = this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.V1_PRIME,
        ).ref(primeId);
        const collection = this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.V1_TRACK,
        );
        return firstValueFrom(collection.query().where('usedBy', '==', primeDoc).get());
    }
}
