import {Vector2, Vector2Data} from './vector2';
import {bignumber, BigNumber} from 'mathjs';
import {INF, NEG_INF} from './numbers';
import {GetData} from './get-data';


/**
 * Defines a transformation scale.
 * Each value is how much each coordinate should be moved.
 *
 * Scale is used to define whether to change the size of the object without distorting it (same ratio on width and
 * height)
 */
export interface Corners {
    x1: BigNumber; // left
    y1: BigNumber; // top
    x2: BigNumber; // right
    y2: BigNumber; // bottom
}


/**
 * An object that calculates the positions of the corners of a rectangle. All calculations are done lazily.
 */
export class CornerPositions {
    /**
     * Constructor.
     * @param rectangle The rectangle for which to calculate the corner positions.
     */
    constructor(
        private readonly rectangle: Rectangle2D,
    ) {
    }

    #topLeft: Vector2 | null = null;
    #topRight: Vector2 | null = null;
    #bottomRight: Vector2 | null = null;
    #bottomLeft: Vector2 | null = null;
    #center: Vector2 | null = null;

    /**
     * The top left corner of the rectangle.
     */
    get topLeft(): Vector2 {
        if (this.#topLeft) {
            return this.#topLeft;
        }
        this.#topLeft = this.rectangle.rotatePointAroundPivot(
            new Vector2(this.rectangle.coordinates.x1, this.rectangle.coordinates.y1),
        );
        return this.#topLeft;
    }

    /**
     * The top right corner of the rectangle.
     */
    get topRight(): Vector2 {
        if (this.#topRight) {
            return this.#topRight;
        }
        this.#topRight = this.rectangle.rotatePointAroundPivot(
            new Vector2(this.rectangle.coordinates.x2, this.rectangle.coordinates.y1),
        );
        return this.#topRight;
    }

    /**
     * The bottom right corner of the rectangle.
     */
    get bottomRight(): Vector2 {
        if (this.#bottomRight) {
            return this.#bottomRight;
        }
        this.#bottomRight = this.rectangle.rotatePointAroundPivot(
            new Vector2(this.rectangle.coordinates.x2, this.rectangle.coordinates.y2),
        );
        return this.#bottomRight;
    }

    /**
     * The bottom left corner of the rectangle.
     */
    get bottomLeft(): Vector2 {
        if (this.#bottomLeft) {
            return this.#bottomLeft;
        }
        this.#bottomLeft = this.rectangle.rotatePointAroundPivot(
            new Vector2(this.rectangle.coordinates.x1, this.rectangle.coordinates.y2),
        );
        return this.#bottomLeft;
    }

    /**
     * The center of the rectangle.
     */
    get center(): Vector2 {
        if (this.#center) {
            return this.#center;
        }
        const rawCorners = this.rectangle.coordinates;
        this.#center = new Vector2(rawCorners.x2.minus(rawCorners.x1), rawCorners.y2.minus(rawCorners.y1))
            .divide(2)
            .add(
                new Vector2(rawCorners.x1, rawCorners.y1),
            );
        return this.#center;
    }

    /**
     * The pivot of the rectangle.
     */
    get pivot(): Vector2 {
        return this.rectangle.position;
    }

    /**
     * Gets a string representation of the top-left and bottom-right corners.
     *
     * @returns The string.
     */
    toString(): string {
        return `{${this.topLeft.toString()}, ${this.bottomRight.toString()}}`;
    }

    diff(corners: CornerPositions) {
        return {
            diffX1: this.topLeft.x.minus(corners.topLeft.x),
            diffY1: this.topLeft.y.minus(corners.topLeft.y),
            diffX2: this.bottomRight.x.minus(corners.bottomRight.x),
            diffY2: this.bottomRight.y.minus(corners.bottomRight.y),
        };
    }
}

export interface Rectangle2DData {
    position: Vector2Data;
    size: Vector2Data;
    rotation: number;
    pivot: Vector2Data;
}

/**
 * A rectangle with a position, size, rotation and pivot.
 */
export class Rectangle2D implements GetData<Rectangle2DData> {
    /**
     * Constructor.
     * @param position The position of the rectangle as a utils.
     * @param size The size of the rectangle as a utils.
     * @param rotation The rotation of the rectangle in radians.
     * @param pivot The pivot position of the rectangle as a utils relative to the top left corner of the rectangle
     * when not rotated.
     */
    constructor(
        public readonly position: Vector2,
        public readonly size: Vector2,
        public readonly rotation: number,
        public readonly pivot: Vector2,
    ) {
    }

    /**
     * The x-coordinate of the rectangle.
     */
    get x(): number {
        return this.position.point.x;
    }

    /**
     * The y-coordinate of the rectangle.
     */
    get y(): number {
        return this.position.point.y;
    }

    /**
     * The width of the rectangle.
     */
    get width(): number {
        return this.size.point.x;
    }

    /**
     * The height of the rectangle.
     */
    get height(): number {
        return this.size.point.y;
    }

    #corners: Corners | null = null;

    /**
     * The corners of the rectangle without the rotation applied.
     */
    get coordinates(): Corners {
        if (this.#corners) {
            return this.#corners;
        }

        const pos1 = this.position.subtract(this.pivot);
        const pos2 = pos1.add(this.size);

        const corners: Corners = {
            x1: pos1.x,
            x2: pos2.x,
            y1: pos1.y,
            y2: pos2.y,
        };
        this.#corners = corners;
        return corners;
    }

    #cornersPositions: CornerPositions | null = null;

    /**
     * Gets the positions of the corners of the rectangle.
     *
     * @returns The positions of the corners.
     */
    get corners(): CornerPositions {
        if (this.#cornersPositions) {
            return this.#cornersPositions;
        }

        this.#cornersPositions = new CornerPositions(this);
        return this.#cornersPositions;
    }

    /**
     * Iterates over the corners of the rectangle clockwise starting from the top left corner.
     */
    * iterCorners(): IterableIterator<Vector2> {
        const corners = this.corners;
        yield corners.topLeft;
        yield corners.topRight;
        yield corners.bottomRight;
        yield corners.bottomLeft;
    }

    public rotatePointAroundPivot(point: Vector2) {
        return point.rotateAround(this.corners.pivot, this.rotation);
    }

    /**
     * Creates a rectangle with the pivot on its center.
     * @param position The position of the rectangle.
     * @param size The size of the rectangle.
     * @param rotation The rotation of the rectangle.
     *
     * @returns The rectangle.
     */
    static centered(position: Vector2, size: Vector2, rotation: number): Rectangle2D {
        return new Rectangle2D(
            position,
            size,
            rotation,
            size.divide(2),
        );
    }

    /**
     * Creates a rectangle with the pivot on its origin.
     * @param position The position of the rectangle.
     * @param size The size of the rectangle.
     * @param rotation The rotation of the rectangle.
     *
     * @returns The rectangle.
     */
    static origin(position: Vector2, size: Vector2, rotation = 0): Rectangle2D {
        return new Rectangle2D(
            position,
            size,
            rotation,
            Vector2.zero,
        );
    }

    /**
     * Creates a rectangle from the top-left corner of the surrounding rectangle.
     * @param srPosition The position of the surrounding rectangle.
     * @param size The size of the rectangle.
     * @param rotation The rotation of the rectangle.
     * @param pivot The pivot of the rectangle.
     *
     * @returns The rectangle.
     */
    static fromSurroundingRectanglePosition(
        srPosition: Vector2,
        size: Vector2,
        rotation: number,
        pivot: Vector2 | null = null,
    ): Rectangle2D {
        if (!pivot) {
            pivot = size.divide(2);
        }
        // get the inner rectangle if it was rotated around the origin
        const rectOnZero = new Rectangle2D(
            Vector2.zero,
            size,
            rotation,
            pivot,
        );

        // get the surrounding rectangle of the inner rectangle on zero
        const surroundingRectangleOnZero = rectOnZero.getSurroundingRectangle();

        // move the inner rectangle to the position of the surrounding rectangle
        const newPosition = srPosition.subtract(surroundingRectangleOnZero.corners.topLeft);
        return new Rectangle2D(
            newPosition,
            size,
            rotation,
            pivot,
        );
    }

    /**
     * Calculates the rectangle that surrounds the given rectangle after it has been rotated around its pivot.
     * The pivot of the returned rectangle is the center of the given rectangle.
     * The rotation of the returned rectangle is always 0.
     *
     * @returns The surrounding rectangle.
     */
    getSurroundingRectangle(): Rectangle2D {
        let xMin: BigNumber = INF;
        let yMin: BigNumber = INF;
        let xMax: BigNumber = NEG_INF;
        let yMax: BigNumber = NEG_INF;

        for (const corner of this.iterCorners()) {
            if (corner.x.lt(xMin)) {
                xMin = corner.x;
            }
            if (corner.y.lt(yMin)) {
                yMin = corner.y;
            }
            if (corner.x.gt(xMax)) {
                xMax = corner.x;
            }
            if (corner.y.gt(yMax)) {
                yMax = corner.y;
            }
        }

        const x = xMin;
        const y = yMin;

        const size = new Vector2(
            xMax.minus(xMin),
            yMax.minus(yMin),
        );

        const pivot = size.divide(2);

        return Rectangle2D.centered(
            new Vector2(x, y).add(pivot),
            size,
            0,
        );
    }

    /**
     * Returns a printable string of the rectangle.
     *
     * @returns The string.
     */
    toString(): string {
        return `Rectangle2D(\n\tposition=${this.position.toString()},\n\tsize=${this.size.toString()},\n\trotation=${this.rotation.toFixed(2)},\n\tpivot=${this.pivot.toString()}\n)`;
    }

    /**
     * Creates a new rectangle rotated around the given pivot.
     * @param rotation How much to rotate the rectangle in radians.
     * @param pivot The pivot position from which to rotate the rectangle. If not given, the global position of the
     * pivot of this rectangle will be used.
     *
     * @returns The rotated rectangle.
     */
    rotate(rotation: number, pivot?: Vector2): Rectangle2D {
        let rotatedPosition: Vector2;
        if (pivot) {
            rotatedPosition = this.position
                .rotateAround(pivot, rotation);
        } else {
            rotatedPosition = this.position;
        }
        return new Rectangle2D(
            rotatedPosition,
            this.size,
            this.rotation + rotation,
            this.pivot,
        );
    }

    /**
     * Creates a new rectangle which is the result of scaling this rectangle's container (frame) from the given
     * ``fromFrame`` to the given ``toFrame`` such that the rectangle's position and size are scaled accordingly.
     * @param fromFrame The frame from which to scale.
     * @param toFrame The frame to which to scale.
     *
     * @returns The scaled rectangle.
     */
    changeFrame(fromFrame: Rectangle2D, toFrame: Rectangle2D): Rectangle2D {
        const surroundingRectangle = this.getSurroundingRectangle();
        // translate

        // use surrounding rectangles instead of the direct frames to have a comparable position
        const translatedSurrounding = Rectangle2D.translateFrame(
            fromFrame,
            toFrame.withSize(fromFrame.size, fromFrame.pivot),
            surroundingRectangle,
        );

        // scale
        const scaledSurrounding = Rectangle2D.scaleFrame(
            fromFrame.withPosition(toFrame.position),
            toFrame,
            translatedSurrounding,
        );

        const scale = toFrame.size.divide(fromFrame.size);
        return Rectangle2D.fromSurroundingRectanglePosition(
            scaledSurrounding.invert(
                scale.x.isNegative(),
                scale.y.isNegative(),
            ).corners.topLeft,
            this.size.scale(scale),
            this.rotation,
            this.pivot.scale(scale),
        );
    }

    /**
     * Creates a new rectangle which is the result of translating the given rectangle from the given ``fromFrame`` to
     * the given ``toFrame`` such that the rectangle's position is translated accordingly.
     * @param fromFrameRectangle The frame from which to scale.
     * @param toFrameRectangle The frame to which to scale.
     * @param rectangle The rectangle to scale.
     * @private
     *
     * @returns The translated rectangle.
     */
    private static translateFrame(
        fromFrameRectangle: Rectangle2D,
        toFrameRectangle: Rectangle2D,
        rectangle: Rectangle2D,
    ): Rectangle2D {
        const translation = toFrameRectangle.corners.center
            .subtract(fromFrameRectangle.corners.center);
        return rectangle.withPosition(
            rectangle.position.add(translation),
        );
    }

    /**
     * Creates a new rectangle which is the result of scaling this rectangle's container (frame) from the given
     * ``fromFrame`` to the given ``toFrame`` such that the rectangle's position and size are scaled accordingly.
     *
     * This method assumes toFrame and fromFrame share the same position and pivot.
     * @param fromFrame The frame from which to scale.
     * @param toFrame The frame to which to scale.
     * @param rectangle The rectangle to scale.
     *
     * @returns The scaled rectangle.
     */
    private static scaleFrame(
        fromFrame: Rectangle2D,
        toFrame: Rectangle2D,
        rectangle: Rectangle2D,
    ): Rectangle2D {
        const scale = toFrame.size.divide(fromFrame.size);

        const oldPivotPosition = rectangle.corners.pivot;
        const pivotRelativePosition = oldPivotPosition.subtract(fromFrame.corners.center);
        const newPivotPosition = toFrame.corners.center.add(pivotRelativePosition.scale(scale));

        const translation = newPivotPosition.subtract(oldPivotPosition);

        return new Rectangle2D(
            rectangle.position.add(translation),
            rectangle.size.scale(scale),
            rectangle.rotation,
            rectangle.pivot.scale(scale),
        );
    }

    /**
     * Creates a copy of this rectangle with the given position.
     * @param position The new position.
     *
     * @returns The new rectangle.
     */
    withPosition(position: Vector2): Rectangle2D {
        return new Rectangle2D(
            position,
            this.size,
            this.rotation,
            this.pivot,
        );
    }

    /**
     * Creates a copy of this rectangle with the given size and pivot (if given).
     * @param size The new size.
     * @param pivot The new pivot.
     * @private
     *
     * @returns The new rectangle.
     */
    private withSize(size: Vector2, pivot?: Vector2): Rectangle2D {
        return new Rectangle2D(
            this.position,
            size,
            this.rotation,
            pivot ?? this.pivot,
        );
    }

    /**
     * Creates a new rectangle that inverts the rectangle on the given axes keeping the shape and position of the
     * rectangle.
     * @param invertX Whether to invert the rectangle on the x-axis.
     * @param invertY Whether to invert the rectangle on the y-axis.
     *
     * @returns The inverted rectangle.
     */
    invert(invertX = true, invertY = true): Rectangle2D {
        const size = new Vector2(
            invertX ? this.size.x.negated() : this.size.x,
            invertY ? this.size.y.negated() : this.size.y,
        );

        const pivot = this.pivot.scale(new Vector2(invertX ? -1 : 1, invertY ? -1 : 1));
        return new Rectangle2D(
            this.position,
            size,
            this.rotation,
            pivot,
        );
    }

    /**
     * Creates a new centered rectangle with the given corners
     * @param corners The corners of the rectangle.
     *
     * @returns The rectangle.
     */
    static fromCorners(corners: Corners): Rectangle2D {
        const topLeftPosition = new Vector2(corners.x1, corners.y1);
        const size = new Vector2(corners.x2.minus(corners.x1), corners.y2.minus(corners.y1));
        return Rectangle2D.centered(
            topLeftPosition.add(size.divide(2)),
            size,
            0,
        );
    }

    /**
     * Finds the rectangle that fits inside such that it keeps the given ratio.
     * @param ratio The ratio to keep (x / y).
     *
     * @returns The size of the rectangle that fits inside.
     */
    fitInside(ratio: number | BigNumber): Vector2 {
        ratio = bignumber(ratio);
        const currentRatio = this.size.x.div(this.size.y);
        if (currentRatio.gt(ratio)) {
            // too wide
            const newWidth = this.size.y.times(ratio);
            return new Vector2(newWidth, this.size.y);
        }
        // too high
        const newHeight = this.size.x.div(ratio);
        return new Vector2(this.size.x, newHeight);
    }

    /**
     * Gets the raw data of this rectangle
     */
    get rawData(): Rectangle2DData {
        return {
            position: this.position.rawData,
            size: this.size.rawData,
            pivot: this.pivot.rawData,
            rotation: this.rotation,
        };
    }

    /**
     * Creates a {@link Rectangle2D} from its data
     * @param data The data of this rectangle.
     *
     * @returns The rectangle.
     */
    static fromRectangle(data: Rectangle2DData): Rectangle2D {
        return new Rectangle2D(
            Vector2.fromPoint(data.position),
            Vector2.fromPoint(data.size),
            data.rotation,
            Vector2.fromPoint(data.pivot),
        );
    }

    /**
     * Preloads all points.
     *
     * @returns This instance after preloading.
     */
    preload(): this {
        this.position.preload();
        this.size.preload();
        this.pivot.preload();
        return this;
    }
}
