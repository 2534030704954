import {ZodSchemaBuild} from '@nirby/store/models';
import {z} from 'zod';
import {GradientData, GradientStop, NirbyColorRaw} from '@nirby/board-structures';
import Color from 'color';


export const NIRBY_COLOR_SCHEMA: ZodSchemaBuild<NirbyColorRaw> = z
    .string()
    .transform((value): NirbyColorRaw => {
        let color: Color;
        try {
            color = new Color(value);
        } catch (e) {
            return 'rgba(0,0,0,1)';
        }
        return `rgba(${color.red()},${color.green()},${color.blue()},${color.alpha()})`;
    })
    .describe('Nirby Color');

export const GRADIENT_STOP_SCHEMA: ZodSchemaBuild<GradientStop<NirbyColorRaw>> = z.object({
    color: NIRBY_COLOR_SCHEMA,
    stop: z.number(),
})
    .describe('Gradient Stop');

export const GRADIENT_SCHEMA: ZodSchemaBuild<GradientData> = z.object({
    type: z.enum(['linear', 'radial']),
    stops: GRADIENT_STOP_SCHEMA.array(),
})
    .describe('Gradient');
