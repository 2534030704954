import {Injectable} from '@angular/core';
import {RouteParametersService} from '../route-parameters.service';
import {Database} from '@nirby/ngutils';
import {COLLECTION_KEYS} from '@nirby/store/collections';
import {NirbyCollection, NirbyDocument} from '@nirby/store/base';
import {V1Prime} from '@nirby/models/editor-v1';
import {distinctUntilChanged, map, Observable, switchMap} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
/**
 * V1 Primes service.
 */
export class V1PrimesService {
    /**
     * Constructor.
     * @param routeParams Route parameters service.
     * @param db Firestore service.
     * @param http The HTTP client
     */
    constructor(
        private routeParams: RouteParametersService,
        private readonly db: Database,
    ) {
    }

    /**
     * Gets a Prime collection for a workspace
     * @param workspaceId Workspace ID
     *
     * @returns - The Prime collection
     */
    public collection(workspaceId: string): NirbyCollection<V1Prime> {
        return this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.V1_PRIME,
        );
    }

    /**
     * Prime ID
     */
    get primeId(): string {
        return this.routeParams.primeId;
    }

    /**
     * Watches the Primes available in the current workspace.
     */
    get workspacePrime$(): Observable<NirbyDocument<V1Prime>[]> {
        return this.routeParams.widgetId$.pipe(
            distinctUntilChanged(),
            switchMap((widgetId) =>
                this.collection(widgetId).query().orderBy('name').watch(),
            ),
        );
    }

    /**
     * Prime reference
     */
    get primeRef$(): Observable<[string, string] | null> {
        return this.routeParams.watchMany(['widgetId', 'primeId']).pipe(
            map((s): [string, string] | null => {
                return s.length === 2 &&
                typeof s[0] === 'string' &&
                typeof s[1] === 'string'
                    ? (s as [string, string])
                    : null;
            }),
        );
    }
}
