import {ZodSchemaBuild} from '@nirby/store/models';
import {IMAGE_RECORD_SCHEMA, ImageRecord, VIDEO_RECORD_SCHEMA, VideoRecord} from '@nirby/media-models';
import {ANY_BLOCK_SCHEMA, AnyBlock, Vector2d, VECTOR_2D_SCHEMA} from '@nirby/models/nirby-player';
import {APP_CARD_MIGRATOR, AppCard} from '../cards';
import {Directory, DIRECTORY_MIGRATOR} from '../pop';
import {z} from 'zod';
import {Migrator} from '@nirby/store/migrator';
import {v4} from 'uuid';
import {buildUploadedReferenceSchema} from '@nirby/store/proxy';
import {NirbyDocumentReference} from '@nirby/store/base';
import {ANY_CARD_ACTION_SCHEMA, NirbyAction} from '@nirby/models/actions';

export type PrimeSourceActionTrigger = 'start' | 'finish';
export type PrimeTrackActionTrigger = 'appear' | 'disappear';

// Prime

export const APP_PRIME_TRACK_CARD_SCHEMA: ZodSchemaBuild<AppPrimeTrackCard> =
    buildAppPrimeTrackSchema(
        'card',
        z.object({
            card: buildUploadedReferenceSchema<AppCard>(
                () => APP_CARD_MIGRATOR,
            ),
            position: VECTOR_2D_SCHEMA,
            rotation: z.number().default(0),
            scale: z.number().default(1),
        }),
    );

export const APP_PRIME_TRACK_BLOCK_SCHEMA: ZodSchemaBuild<AppPrimeTrackBlock> =
    buildAppPrimeTrackSchema(
        'block',
        z.object({
            block: ANY_BLOCK_SCHEMA,
        }),
    );
export type AnyAppPrimeTrack = AppPrimeTrackCard | AppPrimeTrackBlock;

export const ANY_APP_PRIME_TRACK_SCHEMA: ZodSchemaBuild<AnyAppPrimeTrack> = z
    .union([APP_PRIME_TRACK_CARD_SCHEMA, APP_PRIME_TRACK_BLOCK_SCHEMA])
    .describe('Any app prime track');

export const PRIME_TRACK_MIGRATOR = new Migrator<AnyAppPrimeTrack>(
    ANY_APP_PRIME_TRACK_SCHEMA,
    (): AnyAppPrimeTrack => {
        // noinspection JSDeprecatedSymbols
        return {
            type: 'block',
            zIndex: 0,
            name: 'Untitled Track',
            location: null,
            actions: {
                appear: [],
                disappear: [],
            },
            properties: {
                block: {
                    hash: v4(),
                    type: 'Text',
                    content: {
                        text: 'Untitled Track',
                        verticalAlign: 'middle',
                        alignment: 'center',
                        fontScale: 1,
                        color: '#000000',
                        decoration: '',
                        style: 'normal',
                        fontFamily: 'Roboto',
                        lineHeight: 1,
                    },
                    style: {},
                    actions: {
                        click: [],
                    },
                    position: [
                        {
                            x: 0,
                            y: 0,
                        },
                        {
                            x: 230,
                            y: 32,
                        },
                    ],
                    rotation: 0,
                    scale: 1,
                },
            },
            playStyle: 'loop',
            usedBy: null,
        };
    },
);

export interface Prime {
    name: string;
    logo: ImageRecord | null;
    background: ImageRecord | null;
    thumbnail: ImageRecord | null;
    initialSource: NirbyDocumentReference<AnyPrimeSource> | null;
    defaultLanguage: string | null;
    showNavigationControls: boolean;
    parent?: NirbyDocumentReference<Directory> | null;
    videoSourceType: VideoRecord['type'];
}

export const PRIME_SCHEMA: ZodSchemaBuild<Prime> = z.object({
    name: z.string(),
    logo: IMAGE_RECORD_SCHEMA.nullable().default(null),
    background: IMAGE_RECORD_SCHEMA.nullable().default(null),
    thumbnail: IMAGE_RECORD_SCHEMA.nullable().default(null),
    initialSource: buildUploadedReferenceSchema<AnyPrimeSource>(
        () => PRIME_SOURCE_MIGRATOR,
    )
        .nullable()
        .default(null),
    defaultLanguage: z.string().nullable().default(null),
    showNavigationControls: z.boolean().default(false),
    parent: buildUploadedReferenceSchema<Directory>(() => DIRECTORY_MIGRATOR)
        .nullable()
        .default(null),
    videoSourceType: VIDEO_RECORD_SCHEMA.shape.type.default('vimeo'),
}).describe('Prime');

export const LEGACY_PRIME_MIGRATOR = new Migrator<Prime>(PRIME_SCHEMA, (): Prime => {
    return {
        background: null,
        defaultLanguage: 'en',
        initialSource: null,
        logo: null,
        name: 'Untitled Prime',
        parent: null,
        showNavigationControls: true,
        thumbnail: null,
        videoSourceType: 'vimeo',
    };
});

// Prime source

interface BaseSourceLink<TType extends string, TProperties extends object> {
    type: TType;
    destiny: NirbyDocumentReference<AnyPrimeSource>;
    properties: TProperties;
}

export type PrimeSourceLinkVideo = BaseSourceLink<
    'video',
    {
        time: number;
    }
>;

export const PRIME_SOURCE_LINK_VIDEO_SCHEMA: ZodSchemaBuild<PrimeSourceLinkVideo> =
    z.object({
        type: z.literal('video'),
        destiny: buildUploadedReferenceSchema<AnyPrimeSource>(
            () => PRIME_SOURCE_MIGRATOR,
        ),
        properties: z.object({
            time: z.number(),
        }),
    });
export type AnyPrimeSourceLink = PrimeSourceLinkVideo;

export const ANY_PRIME_SOURCE_LINK_SCHEMA: ZodSchemaBuild<AnyPrimeSourceLink> =
    PRIME_SOURCE_LINK_VIDEO_SCHEMA;

export const APP_PRIME_SOURCE_VIDEO_SCHEMA: ZodSchemaBuild<AppPrimeSourceVideo> =
    z.object({
        name: z.string(),
        type: z.literal('video'),
        source: VIDEO_RECORD_SCHEMA,
        properties: z.object({
            pauseBeforeEndMs: z.number().nullable().default(null),
        }),
        actions: z.object({
            start: z.array(ANY_CARD_ACTION_SCHEMA).default([]),
            finish: z.array(ANY_CARD_ACTION_SCHEMA).default([]),
        }),
        nextSource: ANY_PRIME_SOURCE_LINK_SCHEMA.nullable().default(null),
        aggroPower: z.number().default(0),
        usedBy: buildUploadedReferenceSchema<Prime>(() => LEGACY_PRIME_MIGRATOR),
    });

interface BasePrimeSource<
    TType extends string,
    TSource extends object,
    TProperties extends object
> {
    name: string;
    type: TType;
    source: TSource;
    properties: TProperties;
    actions: Record<PrimeSourceActionTrigger, NirbyAction[]>;
    nextSource: AnyPrimeSourceLink | null;
    aggroPower: number;
    usedBy: NirbyDocumentReference<Prime> | null;
}

export type AppPrimeSourceVideo = BasePrimeSource<'video', VideoRecord, {
    pauseBeforeEndMs?: number | null;
}>;
export type AnyPrimeSource = AppPrimeSourceVideo;

export const ANY_PRIME_SOURCE_SCHEMA: ZodSchemaBuild<AnyPrimeSource> =
    APP_PRIME_SOURCE_VIDEO_SCHEMA;

export const PRIME_SOURCE_MIGRATOR = new Migrator<AnyPrimeSource>(
    ANY_PRIME_SOURCE_SCHEMA,
    (): AnyPrimeSource => {
        return {
            name: 'Untitled Source',
            type: 'video',
            source: {
                type: 'vimeo',
                id: '',
                thumbnail: null,
                title: '',
                metadata: {},
            },
            properties: {
                pauseBeforeEndMs: null,
            },
            actions: {
                start: [],
                finish: [],
            },
            nextSource: null,
            aggroPower: 0,
            usedBy: null,
        };
    },
);

// Prime tracks

export interface AppTrackLocation {
    source: NirbyDocumentReference<AppPrimeSourceVideo>;
    from: number;
    to: number;
}

export const APP_TRACK_LOCATION_SCHEMA: ZodSchemaBuild<AppTrackLocation> =
    z.object({
        source: buildUploadedReferenceSchema<AppPrimeSourceVideo>(
            () => PRIME_SOURCE_MIGRATOR,
        ),
        from: z.number().default(0),
        to: z.number().default(0),
    });

export type PrimeTrackPlayStyle = 'loop' | 'play' | 'pause';

export const PRIME_TRACK_PLAY_STYLE_SCHEMA: ZodSchemaBuild<PrimeTrackPlayStyle> =
    z.union([z.literal('loop'), z.literal('play'), z.literal('pause')]);

interface BaseAppPrimeTrack<TType extends string, TProperties extends object> {
    zIndex: number;
    type: TType;
    name: string;
    location: AppTrackLocation | null;
    actions: {
        [key in PrimeTrackActionTrigger]?: NirbyAction[];
    };
    properties: TProperties;
    playStyle: PrimeTrackPlayStyle;
    usedBy: NirbyDocumentReference<Prime> | null;
}

/**
 * Builds a schema for an app prime track
 * @param type The type of the track
 * @param propertiesSchema The schema for the properties of the track
 *
 * @returns The schema for the track
 */
function buildAppPrimeTrackSchema<
    TType extends string,
    TProperties extends object
>(
    type: TType,
    propertiesSchema: ZodSchemaBuild<TProperties>,
): ZodSchemaBuild<BaseAppPrimeTrack<TType, TProperties>> {
    return z.lazy(() =>
        z.object({
            type: z.literal(type),
            properties: propertiesSchema,
            zIndex: z.number().default(0),
            name: z.string(),
            location: APP_TRACK_LOCATION_SCHEMA.nullable().default(null),
            actions: z.object({
                appear: z.array(ANY_CARD_ACTION_SCHEMA).default([]),
                disappear: z.array(ANY_CARD_ACTION_SCHEMA).default([]),
            }),
            playStyle: PRIME_TRACK_PLAY_STYLE_SCHEMA.default('loop'),
            usedBy: buildUploadedReferenceSchema<Prime>(() => LEGACY_PRIME_MIGRATOR)
                .nullable()
                .default(null),
        }),
    ) as unknown as ZodSchemaBuild<BaseAppPrimeTrack<TType, TProperties>>;
}

export type AppPrimeTrackCard = BaseAppPrimeTrack<
    'card',
    {
        card: NirbyDocumentReference<AppCard>;
        position: Vector2d;
        rotation: number;
        scale: number;
    }
>;
export type AppPrimeTrackBlock<TBlock extends AnyBlock = AnyBlock> =
    BaseAppPrimeTrack<
        'block',
        {
            block: TBlock;
        }
    >;
