import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroup, UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import 'firebase/firestore';
import {BlockActionComponent} from '../../blocks/block-action.component';
import {PrimeSourcesService, PrimesService, WorkspaceService} from '@nirby/shared/database';
import {VideoLinkAction} from '@nirby/models/actions';
import {DropdownItem} from '@nirby/shared/widgets';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
    selector: 'nirby-action-video-link',
    templateUrl: './action-video-link.component.html',
    styleUrls: ['./action-video-link.component.scss'],
})
/**
 * Component to edit a video link action.
 */
export class ActionVideoLinkComponent
    extends BlockActionComponent<VideoLinkAction>
    implements OnInit, OnDestroy {
    goToOptions$: Observable<DropdownItem<null | string>[]>;
    timeControl = new UntypedFormControl(0);
    videosControl = new UntypedFormControl(null);
    ignoreAggroGenerationControl = new UntypedFormControl(false);

    form = new FormGroup({
        time: this.timeControl,
        key: this.videosControl,
        ignoreAggroGeneration: this.ignoreAggroGenerationControl,
    });

    /**
     * Constructor.
     * @param sources - Prime sources service.
     * @param router - Router.
     * @param route - Activated route.
     * @param workspaces - Workspace service.
     * @param primes - Primes service.
     */
    constructor(
        private sources: PrimeSourcesService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly workspaces: WorkspaceService,
        private readonly primes: PrimesService,
    ) {
        super();
        this.goToOptions$ = this.sources.availableVideos$.pipe(
            map((siblings) => {
                return [
                    {
                        label: 'Select a video...',
                        value: null,
                    },
                    ...siblings
                        .sort((a, b) => a.data.name.localeCompare(b.data.name))
                        .map((s) => ({
                            label: s.data.name,
                            value: s.ref.id,
                        })),
                ];
            }),
        );
    }

    /**
     * Sets the form data.
     * @param content - Content to set.
     */
    setData(content: VideoLinkAction | null): void {
        if (!content) {
            return;
        }

        this.videosControl.setValue(content.options.key ?? null);
        this.timeControl.setValue(content.options.time);
        this.ignoreAggroGenerationControl.setValue(content.options.ignoreAggroGeneration ?? false);
    }

    /**
     * Gets the URL to preview a Source.
     * @param sourceId - Source ID.
     *
     * @returns The URL.
     */
    getPreviewUrlForSource(sourceId: string): string {
        const primeId = this.primes.primeId;
        const workspaceId = this.workspaces.workspaceId;
        return `/workspaces/${workspaceId}/primes/${primeId}/preview?_v=${sourceId}`;
    }
}
