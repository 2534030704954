import { Injectable } from '@angular/core';
import { Scene } from '@nirby/models/editor-v1';
import { Database } from '@nirby/ngutils';
import { NirbyDocument } from '@nirby/store/base';
import { COLLECTION_KEYS } from '@nirby/store/collections';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/***/
export class V1PrimeScenesService {
    /***/
    constructor(
        private db: Database,
    ) {}

    /**
     * Get all the tracks from a Prime
     * @param workspaceId The workspace ID
     * @param primeId The Prime ID
     *
     * @returns - An observable that resolves to the tracks
     */
    getScenesForPrime(
        workspaceId: string,
        primeId: string,
    ): Promise<NirbyDocument<Scene>[]>{
        const primeDoc = this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.V1_PRIME,
        ).ref(primeId);
        const collection = this.db.collection(
            COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
            COLLECTION_KEYS.V1_SCENE,
        );
        return firstValueFrom(collection.query().where('usedBy', '==', primeDoc).get());
    }
}
