import {Injectable} from '@angular/core';
import {catchError, combineLatest, defer, from, lastValueFrom, Observable, of, Subject, timer} from 'rxjs';
import {
    debounce,
    distinctUntilChanged,
    first,
    last,
    map,
    pairwise,
    scan,
    share,
    shareReplay,
    startWith,
    switchMap,
    tap,
} from 'rxjs/operators';

export type LoadScreenType = 'toast' | 'block';

/**
 * An async process with a result and a loading label.
 *
 * The result corresponds to the last value emitted by the process observable.
 */
class LoadProcess<T> {
    public static DEFAULT_LOADING_LABEL = '';

    /**
     * Constructor.
     * @param process$ The process observable.
     * @param type The type of the process.
     * @param label The loading label.
     */
    constructor(
        private readonly process$: Observable<T>,
        public readonly type: LoadScreenType,
        public readonly label: string = LoadProcess.DEFAULT_LOADING_LABEL,
    ) {
    }

    private finished = false;

    /**
     * Whether the process is finished.
     */
    public get isFinished(): boolean {
        return this.finished;
    }

    /**
     * The result of the process.
     */
    public readonly result$: Observable<T> = this.process$.pipe(
        last(),
        tap(() => this.finished = true),
        share(),
    );

    public readonly finished$: Observable<boolean> = this.result$.pipe(
        map(() => true),
        catchError(() => {
            this.finished = true;
            return of(true);
        }),
        startWith(false),
    );

    /**
     * The label of this screen.
     */
    public readonly label$: Observable<string | null> = this.finished$.pipe(
        map(finished => finished ? null : this.label),
        share(),
    );
}

@Injectable({
    providedIn: 'root',
})
/**
 * A service to manage loading screens.
 */
export class LoadScreenService {

    private loader = new Subject<LoadProcess<unknown>>();
    private readonly loadingItemsList$ = this.loader.pipe(
        // store unfinished item to an array
        scan<LoadProcess<unknown>, LoadProcess<unknown>[]>((acc, curr) => {
            return [...acc.filter(item => !item.isFinished), curr];
        }, []),
        // start with an empty array
        startWith<LoadProcess<unknown>[]>([]),
        shareReplay({refCount: true, bufferSize: 1}),
    );

    /**
     * @deprecated Prefer using the {@link watchLoadingLabel} method.
     */
    public readonly loadingMessage$ = this.watchLoadingLabel(null, false).pipe(
        shareReplay({refCount: true, bufferSize: 1}),
    );

    /**
     * Watch the loading label for a given load type.
     * @param type The type of the loading screen to show. If not given, it will watch all types.
     * @param shouldDebounce Whether to debounce the label to avoid quick changes.
     *
     * @returns - An observable that emits the current loading label.
     */
    public watchLoadingLabel(type: LoadScreenType | null = null, shouldDebounce = true): Observable<string | null> {
        return this.loadingItemsList$.pipe(
            switchMap(items => {
                if (type) {
                    items = items.filter(item => item.type === type);
                }
                return items.length > 0 ? combineLatest(items.map(item => item.label$)) : of([]);
            }),
            map(labels => labels.filter((m): m is string => m !== null)),
            // get the labels of the item that are still loading
            map(labels => {
                const messages = [...new Set(labels)];
                if (messages.length === 0) {
                    // if no labels exist, then there are not any item remaining
                    return null;
                } else if (messages.length === 1) {
                    // if only one loading label exist, then show the label of that item
                    return messages[0];
                }
                // if more than one loading label exist, then show the default label
                return LoadProcess.DEFAULT_LOADING_LABEL;
            }),
            distinctUntilChanged(),
            startWith(null),
            pairwise(),
            debounce(([prev, curr]) => {
                if (!shouldDebounce) {
                    return of(0);
                }

                const timer$ = timer(500);

                // debounce on different labels
                if (curr !== null && prev !== null) {
                    return timer$;
                }

                // if hiding, debounce
                if (prev !== null && curr === null) {
                    return timer$;
                }

                return of(0);
            }),
            map(([, curr]) => curr),
        );
    }

    /**
     * Watches the current loading messages.
     * @param type The type of the loading screen to show.
     *
     * @returns - An observable that emits the current loading messages.
     */
    public watchLoadingMessages(type?: LoadScreenType): Observable<string[]> {
        return this.loadingItemsList$.pipe(
            // combine the labels to be used by each item
            switchMap((items) => {
                if (items.length === 0) {
                    return of([]);
                }
                const hasBlockLoader = items.some(item => item.type === 'block');
                if (hasBlockLoader) {
                    return [];
                }
                if (type) {
                    items = items.filter(item => item.type === type);
                }
                return combineLatest(items.map(item => item.label$));
            }),
            map(labels => labels.filter((m): m is string => m !== null)),
        );
    }

    /**
     * Watches if a process is currently loading.
     * @param type If given, it will only check if any process is loading with the given type.
     *
     * @returns - An observable that emits true or false depending on if a process is loading.
     */
    isLoading(type?: LoadScreenType): Observable<boolean> {
        return this.loadingItemsList$.pipe(
            map(items => {
                let filtered = items;
                if (type) {
                    filtered = items.filter(item => item.type === type);
                }
                return filtered.length > 0;
            }),
        );
    }

    /**
     * Watches if a process with the given message is currently loading.
     * @param withMessage The message to watch.
     *
     * @returns - An observable that emits true or false depending on if a process is loading.
     */
    isLoadingWithMessage(withMessage: string): Observable<boolean> {
        return this.watchLoadingMessages().pipe(
            map(messages => messages.filter(message => message === withMessage).length > 0),
        );
    }

    /**
     * Will show screen while the promise is running
     * @param fn Function that generates the promise
     * @param type The type of the loading screen to show.
     * @param label The label to show while loading
     *
     * @returns - Same promise as input with a {@link share} operator.
     */
    untilCompletion<T>(fn: Promise<T> | (() => Promise<T>), type?: LoadScreenType, label?: string | null): Promise<T>;

    /**
     * Will show screen until the given observable resolves.
     * @param observable The observable
     * @param type The type of the loading screen to show.
     * @param label The label to show while loading
     *
     * @returns - Same observable as input with a {@link share} operator.
     */
    untilCompletion<T>(observable: Observable<T>, type?: LoadScreenType, label?: string | null): Observable<T>;

    /**
     * Waits for the last value to be emitted.
     * @param process The process to watch.
     * @param type The type of the loading screen to show.
     * @param label The label to show while loading
     *
     * @returns - Same observable as input with a {@link share} operator.
     */
    untilCompletion<T>(
        process: Observable<T> | (() => Promise<T>) | Promise<T>,
        type: LoadScreenType = 'toast',
        label: string | null = null,
    ): Promise<T> | Observable<T> {
        if (process instanceof Function) {
            const fn = process;
            const observable: Observable<T> = defer(() => from(fn()));
            return lastValueFrom(this.untilCompletion<T>(observable, type, label));
        }
        if (process instanceof Promise) {
            const observable: Observable<T> = defer(() => from(process));
            return lastValueFrom(this.untilCompletion<T>(observable, type, label));
        }
        const item = new LoadProcess(process, type, label ?? undefined);
        this.loader.next(item);
        return item.result$;
    }

    /**
     * Waits for the first value to be emitted.
     * @param process The observable to watch.
     * @param type The type of the loading screen to show.
     * @param label The label to show while loading
     *
     * @returns - Same observable as input with a {@link share} operator.
     */
    untilFirstValue<T>(process: Observable<T>, type: LoadScreenType, label?: string): Observable<T> {
        process = process.pipe(
            share(),
        );
        const item = new LoadProcess(process.pipe(first()), type, label ?? undefined);
        this.loader.next(item);
        return process;
    }
}
