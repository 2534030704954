import { Injectable } from '@angular/core';
import { V1Prime } from '@nirby/models/editor-v1';
import { Database } from '@nirby/ngutils';
import { NirbyDocument } from '@nirby/store/base';
import { COLLECTION_KEYS } from '@nirby/store/collections';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
/**
 * 
 */
export class V1PrimeService {

  /***/
  constructor(
    private db: Database,
) {}
  /**
     * Get all the tracks from a Prime
     * @param primeId The Prime ID
     * @param workspaceId The wokrspace ID
     * @returns - An observable that resolves to the tracks
     */
  getPrimeById(
    primeId: string,
    workspaceId: string
): Observable<NirbyDocument<V1Prime >| null>{
    const primeDoc = this.db.collection(
        COLLECTION_KEYS.WORKSPACES.doc(workspaceId),
        COLLECTION_KEYS.V1_PRIME,
    ).get(primeId)
    return primeDoc
}
}
