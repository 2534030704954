import {IMAGE_RECORD_SCHEMA, ImageRecord, VIDEO_RECORD_SCHEMA, VideoRecord} from '@nirby/media-models';
import {GRADIENT_SCHEMA, NIRBY_COLOR_SCHEMA} from './types';
import {z, ZodObject} from 'zod';
import {ZodSchemaBuild} from '@nirby/store/models';
import {
    EasingMethod,
    FontSource,
    FunctionSegmentDescription,
    GetData,
    GetDataRaw,
    LerpableType,
    LerpableTypeName,
    NirbyColorRaw, Rectangle2D,
    Rectangle2DData,
    RectangleBorderRadius,
    SegmentedFunctionDescription,
    ShapeAnimationsDescription,
    ShapeProperties,
    ShapePropertiesCommon,
    ShapePropertiesShared,
    StageObjectMetadata,
    TextHorizontalAlignment,
    TextVerticalAlignment, Vector2,
    Vector2Data, VectorPath,
    VectorPathData,
    VectorPointData,
} from '@nirby/board-structures';

import Color from 'color';

type ShapeToElementProperty<T> = T extends Color ?
    NirbyColorRaw :
    T extends GetData<infer D> ?
        D :
        T;


export type ShapeToElementProperties<T extends object> = {
    [Key in keyof T]: ShapeToElementProperty<T[Key]>
};

export const VECTOR_2_SCHEMA: ZodSchemaBuild<Vector2Data> = z.object({
    x: z.number(),
    y: z.number(),
});

export const RECTANGLE_2D_SCHEMA: ZodSchemaBuild<Rectangle2DData> = z.object({
    position: VECTOR_2_SCHEMA,
    size: VECTOR_2_SCHEMA,
    rotation: z.number(),
    pivot: VECTOR_2_SCHEMA,
});

export type ElementPropertiesCommon = ShapeToElementProperties<ShapePropertiesCommon>;


export type ElementPropertiesShared = ShapeToElementProperties<ShapePropertiesShared<{
    image: ImageRecord,
    video: VideoRecord,
    metadata: unknown,
}>>;

export type ElementPropertiesByShape<ShapeType extends keyof ShapeProperties> = {
    [Key in keyof ShapeProperties[ShapeType] | keyof ElementPropertiesCommon]: Key extends keyof ElementPropertiesCommon ?
        ElementPropertiesCommon[Key] :
        (Key extends keyof ElementPropertiesShared ? ElementPropertiesShared[Key] : never)
};

export interface ElementBase<T extends string, ShapeType extends keyof ShapeProperties> {
    type: T;
    parentId: string | null;
    properties: ElementPropertiesByShape<ShapeType>;
    animations: ShapeAnimationsDescription<StageObjectMetadata<ShapeProperties[ShapeType]>>;
}


export const ELEMENT_PROPERTIES_COMMON_SCHEMA: ZodSchemaBuild<ElementPropertiesCommon> = z
    .object({
        position: VECTOR_2_SCHEMA,
        size: VECTOR_2_SCHEMA,
        rotation: z.number().default(0),
        opacity: z.number().default(1),
    })
    .describe('Element Properties Common');


export const VECTOR_POINT_SCHEMA: ZodSchemaBuild<VectorPointData> = z.object({
    position: VECTOR_2_SCHEMA,
    controlPoint1: VECTOR_2_SCHEMA,
    controlPoint2: VECTOR_2_SCHEMA,
});

export const VECTOR_PATH_SCHEMA: ZodSchemaBuild<VectorPathData> = z.object({
    points: z.array(VECTOR_POINT_SCHEMA),
    closed: z.boolean(),
});


export const FONT_SOURCE_SCHEMA: ZodSchemaBuild<FontSource, FontSource> = z.object({
    name: z.string(),
    type: z.enum(['google']),
    weight: z.number(),
});


export const TEXT_HORIZONTAL_ALIGNMENT_SCHEMA: ZodSchemaBuild<TextHorizontalAlignment, TextHorizontalAlignment> = z.union([
    z.literal('center'),
    z.literal('left'),
    z.literal('right'),
]);

export const TEXT_VERTICAL_ALIGNMENT_SCHEMA: ZodSchemaBuild<TextVerticalAlignment, TextVerticalAlignment> =
    z.union([z.literal('bottom'), z.literal('middle'), z.literal('top')]);


export const BORDER_RADIUS_SCHEMA: ZodSchemaBuild<RectangleBorderRadius, RectangleBorderRadius> = z.object({
    topLeft: z.number(),
    topRight: z.number(),
    bottomRight: z.number(),
    bottomLeft: z.number(),
});


export const ELEMENT_PROPERTIES_SHARED_SCHEMA: ZodSchemaBuild<ElementPropertiesShared> = z
    .object({
        imageURL: IMAGE_RECORD_SCHEMA.nullable().default(null),
        videoURL: VIDEO_RECORD_SCHEMA.nullable().default(null),
        fill: z.union([NIRBY_COLOR_SCHEMA, GRADIENT_SCHEMA]).default(NIRBY_COLOR_SCHEMA.parse('#000000')),
        frame: RECTANGLE_2D_SCHEMA.default(Rectangle2D.origin(Vector2.zero, Vector2.one).rawData),
        text: z.string().default(''),
        textSecondary: z.string().default(''),
        textVerticalAlignment: TEXT_VERTICAL_ALIGNMENT_SCHEMA.default('middle'),
        textAlignment: TEXT_HORIZONTAL_ALIGNMENT_SCHEMA.default('center'),
        fontColor: NIRBY_COLOR_SCHEMA.default(NIRBY_COLOR_SCHEMA.parse('#FFFFFF')),
        fontSize: z.number().default(13),
        fontFamily: FONT_SOURCE_SCHEMA.default({
            name: 'Roboto',
            type: 'google',
            weight: 400,
        }),
        fontWeight: z.number().default(400),
        textLineHeight: z.number().default(1.1),
        borderRadius: BORDER_RADIUS_SCHEMA.default({
            topLeft: 0,
            topRight: 0,
            bottomRight: 0,
            bottomLeft: 0,
        }),
        borderWidth: z.number().default(0),
        borderColor: NIRBY_COLOR_SCHEMA.default(NIRBY_COLOR_SCHEMA.parse('#000000')),
        autoplay: z.boolean().default(true),
        icon: z.string().default('\uf00c'),
        emoji: z.string().default('😀'),
        path: VECTOR_PATH_SCHEMA.default(VectorPath.rectangle(1, 1).rawData),
        modified: z.boolean().default(false),
    })
    .describe('Element Properties Shared');

/**
 * @param schema Base schema to choose properties from
 * @param keys List of properties
 * @returns Subset of properties
 */
export function extractSchemaKey<T extends object, Key extends keyof T>(schema: ZodSchemaBuild<T>, keys: Key[]): ZodSchemaBuild<Pick<T, Key>> {
    if (!(schema instanceof ZodObject)) throw Error('Schema is not a Zod Object');
    // {'property_1': true, 'property_2': true}
    const formattedKeys: Partial<{ [K in Key]: true }> = {};
    keys.forEach((k) => formattedKeys[k] = true);
    return schema.pick(formattedKeys as { [K in Key]: true }) as unknown as ZodSchemaBuild<Pick<T, Key>>;
}

export const EASING_METHOD_SCHEMA: ZodSchemaBuild<EasingMethod> = z.enum([
    'linear',
    'quadratic',
    'cubic',
    'quartic',
    'quintic',
    'sigmoid',
    'sine',
    'circular',
    'exp',
]).catch((): EasingMethod => 'linear');

export const LERPABLE_TYPE_NAME_SCHEMA: ZodSchemaBuild<LerpableTypeName<LerpableType>> = z.enum([
    'number',
    'color',
    'vector2',
]).catch((): LerpableTypeName<LerpableType> => 'number');


/**
 * Creates a schema for a function segment description.
 * @param schema Schema for the type of the function segment
 *
 * @returns Schema for a function segment description
 */
export function createFunctionSegmentDescription<T extends LerpableType>(schema: ZodSchemaBuild<GetDataRaw<T>>): ZodSchemaBuild<FunctionSegmentDescription<T>> {
    return z.object({
        xSpan: z.number(),
        y0: schema,
        y1: schema,
        easingMethod: EASING_METHOD_SCHEMA,
        type: LERPABLE_TYPE_NAME_SCHEMA as unknown as ZodSchemaBuild<LerpableTypeName<T>>,
    }) as unknown as ZodSchemaBuild<FunctionSegmentDescription<T>>;
}


export function createSegmentedFunctionDescription<T extends LerpableType>(
    schema: ZodSchemaBuild<GetDataRaw<T>>,
): ZodSchemaBuild<SegmentedFunctionDescription<T>> {
    return z.object({
        segments: z.array(createFunctionSegmentDescription(schema)),
        offset: z.number(),
    });
}

const ANIMATIONS_SCHEMA_SHARED = z.object({
    fontColor: createSegmentedFunctionDescription(NIRBY_COLOR_SCHEMA).optional(),
    fontSize: createSegmentedFunctionDescription(z.number()).optional(),
    fontWeight: createSegmentedFunctionDescription(z.number()).optional(),
    textLineHeight: createSegmentedFunctionDescription(z.number()).optional(),
    borderWidth: createSegmentedFunctionDescription(z.number()).optional(),
    borderColor: createSegmentedFunctionDescription(NIRBY_COLOR_SCHEMA).optional(),
    fill: z.union([createSegmentedFunctionDescription(NIRBY_COLOR_SCHEMA), z.any()]).optional(),
});

export function createAnimationsSchema<T extends object>(
    keys: (keyof z.infer<typeof ANIMATIONS_SCHEMA_SHARED>)[],
): ZodSchemaBuild<ShapeAnimationsDescription<T>> {
    const pickKeys: Partial<{ [K in keyof z.infer<typeof ANIMATIONS_SCHEMA_SHARED>]: true }> = {};
    keys.forEach((k) => pickKeys[k] = true);
    return ANIMATIONS_SCHEMA_SHARED.pick(pickKeys).default({}) as ZodSchemaBuild<ShapeAnimationsDescription<T>>;
}
