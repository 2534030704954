import {NIRBY_ELEMENT_SCHEMA, NirbyElement} from '../elements';
import {BoundedTrack} from './base';
import {z} from 'zod';
import {ZodSchemaBuild} from '@nirby/store/models';
import {buildUploadedReferenceSchema} from '@nirby/store/proxy';
import {V1_SCENE_MIGRATOR} from '../scenes';
import {V1_PRIME_MIGRATOR} from '../v1-prime';
import {Vector2, VectorPath} from '@nirby/board-structures';

/**
 * A track for an element.
 */
export type NirbyTrackElement<ElementType extends NirbyElement['type'] = NirbyElement['type']> = BoundedTrack<'element', {
    element: NirbyElement & { type: ElementType };
}>

export const NIRBY_TRACK_ELEMENT_SCHEMA: ZodSchemaBuild<NirbyTrackElement> = z
    .object({
        type: z.literal('bounded'),
        contentType: z.literal('element'),
        name: z.string(),
        sceneRef: buildUploadedReferenceSchema(() => V1_SCENE_MIGRATOR).nullable(),
        order: z.number(),
        usedBy: buildUploadedReferenceSchema(() => V1_PRIME_MIGRATOR).nullable(),
        properties: z.object({
            element: NIRBY_ELEMENT_SCHEMA.default(
                (): NirbyElement<'rectangle'> => ({
                    parentId: null,
                    properties: {
                        position: Vector2.zero.rawData,
                        size: new Vector2(320, 240).rawData,
                        rotation: 0,
                        opacity: 1,
                        modified: false,
                        path: VectorPath.rectangle(1, 1).rawData,
                        fill: 'rgba(255,255,255,1)',
                        borderWidth: 0,
                        borderColor: 'rgba(0,0,0,1)',
                        borderRadius: {
                            topLeft: 0,
                            topRight: 0,
                            bottomLeft: 0,
                            bottomRight: 0,
                        },
                    },
                    animations: {},
                    type: 'rectangle',
                }),
            ),
            startTimeMs: z.number(),
            endTimeMs: z.number(),
        }),
    })
    .describe('Nirby Track Action');

