/* eslint-disable require-jsdoc */
import { Injectable } from '@angular/core';
import { from, lastValueFrom, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

export interface EmojiData {
    emoji: string;
    name?: string;
    group: string;
    sub_group: string;
    codepoints: string;
}

export interface EmojiGroup {
    name: string;
    emojis: EmojiData[];
    icon?: string;
}

interface IconsData {
    [group: string]: {
        iconPath: string;
    };
}

@Injectable({
    providedIn: 'root',
})
export class EmojiService {
    searchEmojis(query: string, limit = 100, emojis: EmojiData[]): EmojiData[] {
        const re = new RegExp(query);
        let count = 0;
        return emojis
            .filter((emoji) => {
                if (count > limit) {
                    return false;
                }
                if (!emoji.name) {
                    return false;
                }
                const found = emoji.name.search(re) > -1;
                if (found) {
                    count++;
                }
                return found;
            });
    }
    searchGroupedEmojis(query: string): Observable<EmojiGroup[]> {
        return query.length === 0
            ? this.getAllGrouped()
            : this.getAll().pipe(
                map((emojis) => this.groupEmojis(this.searchEmojis(query, 10, emojis))
                ));
    }

    getAll(): Observable<EmojiData[]> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return from(import('../forms/assets/emojis.json')).pipe(
            map((dataset) => Object.values(dataset) as EmojiData[])
        );
    }

    // Método para obtener los íconos desde el archivo icons.json
    private getIconsData(): Observable<IconsData> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return from(import('../forms/assets/emojis-group.json')).pipe(
            map((data) => data.default as IconsData)
        );
    }

    // Método para asignar el icono a cada grupo
    private async assignIconsToGroups(groups: EmojiGroup[]): Promise<void> {
        const iconsData = await lastValueFrom(this.getIconsData());
        groups.forEach((group) => {
            if (iconsData[group.name]) {
                group.icon = iconsData[group.name].iconPath;
            }
        });
    }

    // Método getAllGrouped modificado para asignar los iconos
    getAllGrouped(): Observable<EmojiGroup[]> {
        return this.getAll().pipe(
            mergeMap((emojis: EmojiData[]) => {
                const groupedEmojis = this.groupEmojis(emojis);
                return from(this.assignIconsToGroups(groupedEmojis)).pipe(map(() => groupedEmojis));
            })
        );
    }

    groupEmojis(allEmojis: EmojiData[]): EmojiGroup[] {
        const groups: Record<string, EmojiData[]> = {};
        allEmojis.forEach(
            (e) => (groups[e.group] = [e, ...(groups[e.group] ?? [])])
        );
        return Object.entries(groups).map(([name, emojis]) => ({
            name,
            emojis,
        }));
    }

    async getRandom(): Promise<EmojiData> {
        const allEmojis = await lastValueFrom(this.getAll())
        const randomIndex = Math.floor(Math.random() * allEmojis.length);
        return allEmojis[randomIndex];
    }
}
