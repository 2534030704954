import {EasingMethod} from './easing-methods';
import {LerpableType, LerpableTypeName} from './lerp';
import {GetDataRaw} from '../get-data';
import {NirbyColor, NirbyColorRaw} from '../color';
import {Vector2, Vector2Data} from '../vector2';


export interface FunctionSegmentDescription<T extends LerpableType> {
    xSpan: number;
    y0: GetDataRaw<T>;
    y1: GetDataRaw<T>;
    easingMethod: EasingMethod;
    type: LerpableTypeName<T>;
}

export interface SegmentedFunctionDescription<T extends LerpableType> {
    segments: FunctionSegmentDescription<T>[];
    offset: number;
}


export function buildType<T extends LerpableType>(raw: GetDataRaw<T>, type: LerpableTypeName<T>): T {
    switch (type) {
        case 'number':
            return raw as T;
        case 'color':
            return NirbyColor.fromRaw(raw as NirbyColorRaw) as T;
        case 'vector2':
            return Vector2.fromPoint(raw as Vector2Data) as T;
        default:
            throw Error(`Unknown type: ${type}`);
    }
}
