export interface ArrayComparison<T> {
    current: T[];
    appearing: T[];
    disappearing: T[];
    unchanged: T[];
}


/**
 * Gets a comparison between two arrays, checking which elements appeared and disappeared.
 * @param previous The first array of item.
 * @param current The second array of item.
 * @param compareFn The function to compare the item.
 * @private
 *
 * @returns - The comparison object, containing a key ``appearing`` with the item that are on B, but not on A,
 * a key ``disappearing`` with the item that are on A, but not on B, a key ``unchanged`` with the item that
 * are on both A and B, and a key ``current`` with the current array of item.
 */
export function compareArrays<T>(
    previous: T[],
    current: T[],
    compareFn?: (item: T) => unknown,
): ArrayComparison<T> {
    const fn = compareFn ?? ((item) => item);
    const unchanged = previous.filter((item) =>
        current.map((bI) => fn(bI)).includes(fn(item)),
    );
    const appearing = current.filter(
        (item) => !previous.map((aI) => fn(aI)).includes(fn(item)),
    );
    const disappearing = previous.filter(
        (item) => !current.map((bI) => fn(bI)).includes(fn(item)),
    );
    return {
        appearing,
        disappearing,
        unchanged,
        current: [...current],
    };
}
