import {IMAGE_RECORD_SCHEMA, ImageRecord} from '@nirby/media-models';
import {NirbyDocumentReference} from '@nirby/store/base';
import {Directory} from '@nirby/models/editor';
import {z} from 'zod';
import {ZodSchemaBuild} from '@nirby/store/models';
import {V1_SCENE_DIRECTORY_MIGRATOR} from './scenes';
import {buildUploadedReferenceSchema} from '@nirby/store/proxy';
import {Migrator} from '@nirby/store/migrator';

export interface V1Prime {
    name: string;
    logo: ImageRecord | null;
    background: ImageRecord | null;
    thumbnail: ImageRecord | null;
    defaultLanguage: string | null;
    showNavigationControls: boolean;
    parent?: NirbyDocumentReference<Directory> | null;
}

export const V1_PRIME_SCHEMA: ZodSchemaBuild<V1Prime> = z.object(
    {
        name: z.string().default('untitled prime'),
        logo: IMAGE_RECORD_SCHEMA.nullable().default(null),
        background: IMAGE_RECORD_SCHEMA.nullable().default(null),
        thumbnail: IMAGE_RECORD_SCHEMA.nullable().default(null),
        defaultLanguage: z.string().nullable().default(null),
        showNavigationControls: z.boolean().default(true),
        parent: buildUploadedReferenceSchema(() => {
            return V1_SCENE_DIRECTORY_MIGRATOR;
        }).nullable().default(null),
    },
);

export const V1_PRIME_MIGRATOR = new Migrator<V1Prime>(V1_PRIME_SCHEMA, () => (
    {
        name: 'untitled prime',
        logo: null,
        background: null,
        thumbnail: null,
        defaultLanguage: null,
        showNavigationControls: true,
        parent: null,
    }
));
