import {z} from 'zod';
import {Vector2d, VECTOR_2D_SCHEMA} from '../vector2';
import {ZodSchemaBuild} from '@nirby/store/models';
import {ANY_CARD_ACTION_SCHEMA, NirbyAction} from "@nirby/models/actions";

export type ActionTrigger = 'click' | string;
export type AttributeValue = string | number | boolean | null;

export type BlockActions = {
    [trigger: ActionTrigger]: NirbyAction[];
};

export interface FixedBaseBlock<
    TN extends string,
    TC extends object,
    TS extends object
> {
    position: [Vector2d, Vector2d];
    rotation: number;
    scale?: Vector2d | number;
    type: TN;
    hash: string;
    actions: BlockActions;
    content: TC;
    /**
     * @deprecated Prefer {@link content}
     */
    style: TS;
}

export type BaseBlock<
    TN extends string = string,
    TC extends object = object,
    TS extends object = object
> = FixedBaseBlock<TN, TC, TS>;

/**
 * Builds a block schema.
 * @param type The block type.
 * @param contentSchema The content schema.
 * @param styleSchema The style schema.
 *
 * @returns The block schema.
 */
export function buildBlockSchema<Block extends BaseBlock>(
    type: Block['type'],
    contentSchema: ZodSchemaBuild<Block['content']>,
    styleSchema: ZodSchemaBuild<Block['style']>,
): ZodSchemaBuild<Block> {
    return z.object({
        position: z.tuple([VECTOR_2D_SCHEMA, VECTOR_2D_SCHEMA]),
        rotation: z.number(),
        scale: z.union([VECTOR_2D_SCHEMA, z.number()]).optional(),
        type: z.literal(type),
        hash: z.string(),
        actions: z.object({
            click: z.array(ANY_CARD_ACTION_SCHEMA).default([]),
        }),
        content: contentSchema,
        style: styleSchema,
    }) as ZodSchemaBuild<Block>;
}
