import {
    createAnimationsSchema,
    ELEMENT_PROPERTIES_COMMON_SCHEMA,
    ELEMENT_PROPERTIES_SHARED_SCHEMA,
    ElementBase,
    extractSchemaKey,
} from '../base';
import {z} from 'zod';
import {ZodSchemaBuild} from '@nirby/store/models';

export type ElementImage = ElementBase<'image', 'image'>

export const ELEMENT_IMAGE_SCHEMA: ZodSchemaBuild<ElementImage> = z
    .object({
        type: z.literal('image'),
        parentId: z.string().nullable(),
        properties: z.intersection(
            ELEMENT_PROPERTIES_COMMON_SCHEMA,
            extractSchemaKey(
                ELEMENT_PROPERTIES_SHARED_SCHEMA,
                ['imageURL', 'frame', 'path'],
            ),
        ),
        animations: createAnimationsSchema([]),
    }).describe('Nirby Element Image');
