import {
    collectionGroup,
    collectionSnapshots,
    documentId,
    endAt,
    endBefore,
    FieldPath,
    Firestore,
    getDocs,
    limit,
    orderBy,
    query,
    QueryConstraint,
    startAfter,
    startAt,
    where,
} from '@angular/fire/firestore';
import {
    CollectionKey,
    FieldPathMap,
    NirbyDocument,
    NirbyFieldObjectPath,
    NirbyFieldValue,
    QueryBuilder,
    QueryOperator,
    QueryOrderByDirection,
} from '@nirby/store/base';
import {from, Observable} from 'rxjs';
import {StandardConverter} from './converter';
import {map} from 'rxjs/operators';
import {FirestoreCollection} from './collection';
import {FirestoreNirbyDocument} from './document';

export class FirestoreQueryBuilder<T extends object> extends QueryBuilder<
    T,
    QueryConstraint,
    FieldPath
> {
    constructor(private readonly firestore: FirestoreCollection<T>) {
        super();
    }

    buildPathMap(): FieldPathMap<FieldPath> {
        return FieldPathMap.create(() => documentId());
    }

    override _where(
        field: string | FieldPath,
        operator: QueryOperator,
        value: NirbyFieldValue,
    ): QueryConstraint {
        return where(field, operator, value);
    }

    override _endAt(value: NirbyFieldValue): QueryConstraint {
        return endAt(value);
    }

    override _endBefore(value: NirbyFieldValue): QueryConstraint {
        return endBefore(value);
    }

    override _limit(limitN: number): QueryConstraint {
        return limit(limitN);
    }

    override _orderBy(
        field: NirbyFieldObjectPath<T>,
        direction: QueryOrderByDirection = 'asc',
    ): QueryConstraint {
        return orderBy(field, direction);
    }

    override _startAfter(value: NirbyFieldValue): QueryConstraint {
        return startAfter(value);
    }

    override _startAt(value: NirbyFieldValue): QueryConstraint {
        return startAt(value);
    }

    override watch(): Observable<NirbyDocument<T>[]> {
        return collectionSnapshots(
            query(this.firestore.collection.ref, ...this.constraints),
        ).pipe(
            map((snapshots): NirbyDocument<T>[] =>
                snapshots.map((snapshot) =>
                    FirestoreNirbyDocument.fromFirestore(
                        snapshot,
                        this.firestore.migrator,
                    ),
                ),
            ),
        );
    }

    override get(): Observable<NirbyDocument<T>[]> {
        return from(
            getDocs(query(this.firestore.collection.ref, ...this.constraints)),
        ).pipe(
            map((snapshots): NirbyDocument<T>[] =>
                snapshots.docs.map((snapshot) =>
                    FirestoreNirbyDocument.fromFirestore(
                        snapshot,
                        this.firestore.migrator,
                    )),
            ),
        );
    }

    override getFirst(): Observable<NirbyDocument<T> | null> {
        return this.limit(1)
            .get()
            .pipe(map((documents) => documents[0] ?? null));
    }

    override watchFirst(): Observable<NirbyDocument<T> | null> {
        return this.limit(1)
            .watch()
            .pipe(map((documents) => documents[0] ?? null));
    }
}

export class FirestoreCollectionGroupQueryBuilder<
    T extends object
> extends QueryBuilder<T, QueryConstraint, FieldPath> {
    constructor(
        private readonly firestore: Firestore,
        private readonly key: CollectionKey<T>,
    ) {
        super();
    }

    override _where(
        field: string | FieldPath,
        operator: QueryOperator,
        value: NirbyFieldValue,
    ): QueryConstraint {
        return where(field, operator, value);
    }

    override _endAt(value: NirbyFieldValue): QueryConstraint {
        return endAt(value);
    }

    override _endBefore(value: NirbyFieldValue): QueryConstraint {
        return endBefore(value);
    }

    override _limit(limitN: number): QueryConstraint {
        return limit(limitN);
    }

    override _orderBy(
        field: NirbyFieldObjectPath<T>,
        direction: QueryOrderByDirection = 'asc',
    ): QueryConstraint {
        return orderBy(field, direction);
    }

    override _startAfter(value: NirbyFieldValue): QueryConstraint {
        return startAfter(value);
    }

    override _startAt(value: NirbyFieldValue): QueryConstraint {
        return startAt(value);
    }

    override get(): Observable<NirbyDocument<T>[]> {
        return from(
            getDocs(
                query(
                    collectionGroup(
                        this.firestore,
                        this.key.remoteCollectionName,
                    ).withConverter(StandardConverter.for(this.key.migrator)),
                    ...this.constraints,
                ),
            ),
        ).pipe(
            map((collection) => {
                return FirestoreNirbyDocument.fromQuery<T>(
                    collection.docs,
                    this.key.migrator,
                );
            }),
        );
    }

    override watch(): Observable<NirbyDocument<T>[]> {
        return collectionSnapshots(
            collectionGroup(
                this.firestore,
                this.key.remoteCollectionName,
            ).withConverter(StandardConverter.for(this.key.migrator)),
        ).pipe(
            map((collection) =>
                FirestoreNirbyDocument.fromQuery<T>(
                    collection,
                    this.key.migrator,
                )),
        );
    }

    override getFirst(): Observable<NirbyDocument<T> | null> {
        return this.limit(1)
            .get()
            .pipe(map((documents) => documents[0] ?? null));
    }

    override watchFirst(): Observable<NirbyDocument<T> | null> {
        return this.limit(1)
            .watch()
            .pipe(map((documents) => documents[0] ?? null));
    }

    buildPathMap(): FieldPathMap<FieldPath> {
        return FieldPathMap.create(() => documentId());
    }
}
