import {bignumber} from 'mathjs';
import {GetData} from './get-data';
import {Vector2, Vector2Data} from './vector2';

export interface VectorPointData {
    position: Vector2Data;
    controlPoint1: Vector2Data;
    controlPoint2: Vector2Data;
}

/**
 * A point in a Bézier curve.
 */
export class VectorPoint {
    get data(): VectorPointData {
        return {
            position: this.position.rawData,
            controlPoint1: this.controlPoint1.rawData,
            controlPoint2: this.controlPoint2.rawData,
        };
    }

    /**
     * Constructor.
     * @param position The position of the point.
     * @param controlPoint1 The control point for the previous segment relative to the point.
     * @param controlPoint2 The control point for the next segment relative to the point.
     */
    constructor(
        public readonly position: Vector2,
        public readonly controlPoint1: Vector2,
        public readonly controlPoint2: Vector2,
    ) {
    }

    /**
     * Creates a new point with the same position and control points.
     *
     * @returns The new point.
     */
    public clone(): VectorPoint {
        return new VectorPoint(this.position, this.controlPoint1, this.controlPoint2);
    }

    /**
     * Reflects the control point across the center point.
     * @param staticControlPointIndex The index of the control point to reflect to.
     *
     * @returns The new control point.
     */
    reflectControlPoint(staticControlPointIndex: 1 | 2): VectorPoint {
        const staticPoint = staticControlPointIndex === 1 ? this.controlPoint1 : this.controlPoint2;

        const staticPosition = new Vector2(staticPoint.x, staticPoint.y);
        const center = Vector2.zero;

        const centerToStatic = staticPosition.subtract(center);

        const newPosition = center.add(
            centerToStatic.scale(-1),
        );

        return new VectorPoint(
            this.position,
            staticControlPointIndex === 1 ? staticPosition : newPosition,
            staticControlPointIndex === 2 ? staticPosition : newPosition,
        );
    }

    static fromData(data: VectorPointData): VectorPoint {
        return new VectorPoint(
            Vector2.fromPoint(data.position),
            Vector2.fromPoint(data.controlPoint1),
            Vector2.fromPoint(data.controlPoint2),
        );
    }

    /**
     * Preloads all points in the vector path
     *
     * @returns This vector path.
     */
    preload(): this {
        this.position.preload();
        this.controlPoint1.preload();
        this.controlPoint2.preload();
        return this;
    }
}

export interface BoundingBox {
    position: Vector2;
    size: Vector2;
}

export interface VectorPathData {
    points: VectorPointData[];
    closed: boolean;
}

/**
 * A path made up of Bézier curves.
 */
export class VectorPath implements GetData<VectorPathData> {
    /**
     * Constructor.
     * @param points The points that make up the path.
     * @param closed Whether the path is closed.
     */
    constructor(
        public readonly points: VectorPoint[] = [],
        public readonly closed: boolean = false,
    ) {
    }

    /**
     * Creates a new path with the same points and closed state.
     *
     * @returns The new path.
     */
    public clone(): VectorPath {
        return new VectorPath(this.points.map(point => point.clone()), this.closed);
    }

    replaceCenterPoint(vectorPointIndex: number, vector2: Vector2): this {
        const point = this.points[vectorPointIndex];
        if (!point) {
            throw new Error(`No point at index ${vectorPointIndex}`);
        }

        this.points[vectorPointIndex] = new VectorPoint(
            vector2,
            point.controlPoint1,
            point.controlPoint2,
        );
        return this;
    }

    replacePoint(vectorPointIndex: number, controlPointIndex: 1 | 2, vector2: Vector2, reflect = true): this {
        const point = this.points[vectorPointIndex];
        if (!point) {
            throw new Error(`No point at index ${vectorPointIndex}`);
        }

        const controlPoint1 = controlPointIndex === 1 ? vector2 : point.controlPoint1;
        const controlPoint2 = controlPointIndex === 2 ? vector2 : point.controlPoint2;

        let updatedPoint = new VectorPoint(
            point.position,
            controlPoint1,
            controlPoint2,
        );
        if (reflect) {
            updatedPoint = updatedPoint.reflectControlPoint(controlPointIndex);
        }
        this.points[vectorPointIndex] = updatedPoint;
        return this;
    }

    /**
     * Scales the path by the given factor.
     * @param scale The scale factor.
     *
     * @returns This path.
     */
    scale(scale: Vector2): this {
        for (let i = 0; i < this.points.length; i++) {
            const point = this.points[i];
            this.points[i] = new VectorPoint(
                point.position.scale(scale),
                point.controlPoint1.scale(scale),
                point.controlPoint2.scale(scale),
            );
        }
        return this;
    }


    /**
     * Creates a path for a rectangle
     * @param width The width of the rectangle.
     * @param height The height of the rectangle.
     *
     * @returns The path.
     */
    public static rectangle(width: number, height: number): VectorPath {
        return new VectorPath([
            new VectorPoint(
                new Vector2(0, 0),
                new Vector2(0, 0),
                new Vector2(0, 0),
            ),
            new VectorPoint(
                new Vector2(width, 0),
                new Vector2(0, 0),
                new Vector2(0, 0),
            ),
            new VectorPoint(
                new Vector2(width, height),
                new Vector2(0, 0),
                new Vector2(0, 0),
            ),
            new VectorPoint(
                new Vector2(0, height),
                new Vector2(0, 0),
                new Vector2(0, 0),
            ),
        ], true);
    }

    static circle(center: Vector2, radius: number): VectorPath {
        const controlPointDistance = bignumber(4).dividedBy(3).times(bignumber(2).sqrt().minus(1));

        const points: VectorPoint[] = [];

        const radiusBig = bignumber(radius);

        points.push(
            new VectorPoint(
                new Vector2(center.x, center.y.minus(radiusBig)),
                new Vector2(controlPointDistance.times(radiusBig), 0),
                new Vector2(-controlPointDistance.times(radiusBig), 0),
            ),
        );

        points.push(
            new VectorPoint(
                new Vector2(center.x.plus(radiusBig), center.y),
                new Vector2(0, controlPointDistance.times(radiusBig)),
                new Vector2(0, -controlPointDistance.times(radiusBig)),
            ),
        );

        points.push(
            new VectorPoint(
                new Vector2(center.x, center.y.plus(radiusBig)),
                new Vector2(-controlPointDistance.times(radiusBig), 0),
                new Vector2(controlPointDistance.times(radiusBig), 0),
            ),
        );

        points.push(
            new VectorPoint(
                new Vector2(center.x.minus(radiusBig), center.y),
                new Vector2(0, -controlPointDistance.times(radiusBig)),
                new Vector2(0, controlPointDistance.times(radiusBig)),
            ),
        );

        return new VectorPath(points, true);
    }

    get rawData(): VectorPathData {
        return {
            closed: this.closed,
            points: this.points.map(
                point => point.data,
            ),
        };
    }

    static fromData(data: VectorPathData): VectorPath {
        return new VectorPath(
            data.points.map(
                point => VectorPoint.fromData(point),
            ),
            data.closed,
        );
    }

    /**
     * Preloads all points in the vector path
     *
     * @returns This vector path.
     */
    preload(): this {
        for (const point of this.points) {
            point.preload();
        }
        return this;
    }
}
