import {Component, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Conditional, ConditionalSimple} from '@nirby/conditionals';
import {AppVariable} from '@nirby/models/editor-variables';

export type ConditionalTypes = 'is' | 'is-not' | 'exists' | 'exists-not' | 'true';

@Component({
    selector: 'nirby-conditional-editor-simple',
    templateUrl: './conditional-editor-simple.component.html',
    styleUrls: ['./conditional-editor-simple.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ConditionalEditorSimpleComponent,
            multi: true
        }
    ]
})
export class ConditionalEditorSimpleComponent implements ControlValueAccessor {
    @Input() variables: AppVariable[] = [];
    @Input() variable: string | null = null;

    disabled = false;
    currentType: ConditionalTypes = 'true';

    value: ConditionalSimple = {
        type: 'true',
        properties: {}
    };

    onChange: (v: Conditional) => void = () => {
        return;
    };
    onTouched: () => void = () => {
        return;
    };

    registerOnChange(fn: (v: Conditional) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: Conditional | undefined | null): void {
        if (obj === undefined || obj === null) {
            this.value = {
                type: 'true',
                properties: {}
            };
        } else if (obj.type === 'and' || obj.type === 'or') {
            this.value = {
                type: 'true',
                properties: {}
            };
        } else {
            this.value = obj;
        }
        this.currentType = this.assignType(this.value);
    }

    onTypeChange(type: ConditionalTypes) {
        switch (type) {
            case 'is':
                this.value = {
                    type: 'equals',
                    properties: {
                        target: this.variable ?? '',
                        value: '',
                    }
                };
                break;
            case 'is-not':
                this.value = {
                    type: 'negate',
                    properties: {
                        condition: {
                            type: 'equals',
                            properties: {
                                target: this.variable ?? '',
                                value: '',
                            }
                        }
                    }
                };
                break;
        }

        this.assignType(this.value);
        this.onChange(this.value);
    }

    assignType(condition: Conditional): ConditionalTypes {
        if (condition.type === 'and' || condition.type === 'or') {
            return 'true';
        }
        switch (condition.type) {
            case 'equals':
                return 'is';
            case 'negate': {
                if (condition.properties.condition.type === 'equals') {
                    return 'is-not';
                } else if (condition.properties.condition.type === 'exists') {
                    return 'exists-not';
                }
                return 'true';
            }
            case 'exists':
                return 'exists';
            default:
                return 'true';
        }
    }

    onValueChange(value: ConditionalSimple): void {
        this.value = value;
        this.onChange(value);
    }

    onVariableChange(newVariable: string | null): void {
        this.variable = newVariable;
        if (this.value.type === 'equals') {
            this.value.properties.target = newVariable ?? '';
        } else if (this.value.type === 'negate' && this.value.properties.condition.type === 'equals') {
            this.value.properties.condition.properties.target = newVariable ?? '';
        }
        this.onChange(this.value);
    }
}
