import {IMAGE_RECORD_SCHEMA, ImageRecord, VIDEO_RECORD_SCHEMA, VideoRecord} from '@nirby/media-models';
import {ZodSchemaBuild} from '@nirby/store/models';
import {z} from 'zod';

/**
 * How will a media content fit into the scene.
 */
export type MediaFit = 'contain' | 'cover';

/**
 * Where will the media be positioned in the scene.
 */
export type MediaPosition = 'center' | 'top' | 'right' | 'bottom' | 'left';

export interface SceneBackgroundBase<Type extends string, Properties extends object> {
    type: Type;
    properties: Properties;
}

/**
 * A solid background.
 */
export type SceneBackgroundSolid = SceneBackgroundBase<'solid', {
    color: string;
}>;

/**
 * An image background.
 */
export type SceneBackgroundImage = SceneBackgroundBase<'image', {
    color: string;
    image: ImageRecord;
    fit: MediaFit;
    position: MediaPosition;
}>;

/**
 * A video background (like legacy Prime editor)
 */
export type SceneBackgroundVideo = SceneBackgroundBase<'video', {
    color: string;
    video: VideoRecord;
    fit: MediaFit;
    position: MediaPosition;
}>;

/**
 * A scene background.
 */
export type SceneBackground = SceneBackgroundSolid | SceneBackgroundImage | SceneBackgroundVideo;

/**
 * Schema for the solid background.
 */
export const SCENE_BACKGROUND_SOLID_SCHEMA = z.object({
    type: z.literal('solid'),
    properties: z.object({
        color: z.string(),
    }),
});

/**
 * Schema for the image background.
 */
export const SCENE_BACKGROUND_IMAGE_SCHEMA = z.object({
    type: z.literal('image'),
    properties: z.object({
        color: z.string(),
        image: IMAGE_RECORD_SCHEMA,
        fit: z.union([z.literal('contain'), z.literal('cover')]),
        position: z.union([z.literal('center'), z.literal('top'), z.literal('right'), z.literal('bottom'), z.literal('left')]),
    }),
});

/**
 * Schema for the video background.
 */
export const SCENE_BACKGROUND_VIDEO_SCHEMA = z.object({
    type: z.literal('video'),
    properties: z.object({
        color: z.string(),
        video: VIDEO_RECORD_SCHEMA,
        fit: z.union([z.literal('contain'), z.literal('cover')]),
        position: z.union([z.literal('center'), z.literal('top'), z.literal('right'), z.literal('bottom'), z.literal('left')]),
    }),
});

/**
 * Union of different scene background schemas.
 */
export const SCENE_BACKGROUND_SCHEMA: ZodSchemaBuild<SceneBackground> = z.union([
    SCENE_BACKGROUND_SOLID_SCHEMA,
    SCENE_BACKGROUND_IMAGE_SCHEMA,
    SCENE_BACKGROUND_VIDEO_SCHEMA,
]);
