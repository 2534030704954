import { z } from 'zod';
import {KNOWN_COLORS_SCHEMA, KnownColors} from '../colors';
import { ZodSchemaBuild } from '@nirby/store/models';
import { Migrator } from '@nirby/store/migrator';

/**
 * A folder that contains scenes.
 */
export interface SceneDirectory {
    name: string;
    color: KnownColors | null;
    order: number;
}

export const V1_SCENE_DIRECTORY_SCHEMA: ZodSchemaBuild<SceneDirectory> = z.object({
    name: z.string().default('Untitled Scene Directory'),
    color: KNOWN_COLORS_SCHEMA.nullable().default('blue'),
    order: z.number().default(0)
})

export const V1_SCENE_DIRECTORY_MIGRATOR: Migrator<SceneDirectory> = new Migrator(V1_SCENE_DIRECTORY_SCHEMA, ()=> (
    {
    name:'untitled scene directory',
    color: null,
    order:0
    }
))