import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {distinctUntilChanged, distinctUntilKeyChanged, filter, map, startWith, switchMap} from 'rxjs/operators';
import {LoggerService} from '@nirby/ngutils';
import {BlockController} from '../../../../editor/services/block-controller';
import {NirbyPlayerConfig} from '@nirby/models/nirby-player';
import {NirbyProductType} from '@nirby/analytics-typings';
import {AnyPrimeSource, AppCard} from '@nirby/models/editor';
import {AppVariable} from '@nirby/models/editor-variables';
import {Conditional} from '@nirby/conditionals';
import {NIRBY_PLAYER_CONFIG} from '@nirby/shared/player-config';
import {NirbyDocument} from '@nirby/store/base';
import { NirbyAction, NirbyActionType } from '@nirby/models/actions';

@Component({
    selector: 'nirby-action-input',
    templateUrl: './action-input.component.html',
    styleUrls: ['./action-input.component.scss'],
})
/**
 * Component to set up an action of a block.
 */
export class ActionInputComponent<TMeta = unknown> implements OnInit, OnChanges, OnDestroy {
    private readonly blockFormSubject =
        new BehaviorSubject<BlockController<TMeta> | null>(null);
    @Input() productType: NirbyProductType | null = null;
    @Input() cardSiblings: NirbyDocument<AppCard>[] = [];
    @Input() availableVideos: NirbyDocument<AnyPrimeSource>[] = [];
    @Output() public readonly closeRequest = new EventEmitter<void>();

    /**
     * Sets the block controller.
     * @param value The block controller to set.
     */
    @Input() set controller(value: BlockController<TMeta> | null) {
        this.blockFormSubject.next(value);
    }

    /**
     * Gets the current block controller.
     */
    get controller(): BlockController<TMeta> | null {
        return this.blockFormSubject.getValue();
    }

    /**
     * Gets the current action.
     */
    get action(): NirbyAction | null {
        return this.actionControl.value;
    }

    private readonly blockController$ = this.blockFormSubject.pipe(
        filter((blockForm): blockForm is BlockController<TMeta> => !!blockForm),
    );

    public readonly typeController = new UntypedFormControl('go-to-url');

    /**
     * Constructor.
     * @param config The nirby player config.
     */
    constructor(
        @Inject(NIRBY_PLAYER_CONFIG) private readonly config: NirbyPlayerConfig,
    ) {
    }

    @Input() actionIndex = 0;
    @Input() variables: AppVariable[] = [];

    private readonly actionIndexController = new BehaviorSubject<number>(
        this.actionIndex,
    );

    public readonly actionIndex$ = this.actionIndexController.asObservable();
    public readonly actionControl = new UntypedFormControl(null, Validators.required);

    public readonly conditionalControl = new UntypedFormControl(undefined);

    private readonly subscription = new Subscription();
    public readonly actionType$: Observable<NirbyActionType> =
        this.typeController.valueChanges.pipe(
            filter((b): b is NirbyActionType => !!b),
        );

    public readonly action$ = this.actionIndex$.pipe(
        switchMap((index) =>
            this.blockController$.pipe(
                switchMap((form) => form.actions.actions$),
                map((block) => block['click'][index] ?? null),
                filter((s): s is NirbyAction => !!s),
                distinctUntilKeyChanged('type'),
            ),
        ),
    );
    public readonly isLastAction$ = this.actionIndex$.pipe(
        switchMap((index) =>
            this.blockController$.pipe(
                switchMap((form) => form.actions.actions$),
                map((block) => block['click'].length - 1 === index),
            ),
        ),
    );
    enabledConditionControl = new UntypedFormControl(false);

    /**
     * Subscribes to the form changes.
     */
    ngOnInit(): void {
        this.actionIndexController.next(this.actionIndex);
        this.subscription.add(
            this.action$.subscribe((action) => {
                // Update action
                this.typeController.setValue(action.type, {emitEvent: false});
                this.actionControl.setValue(action);
                this.enabledConditionControl.setValue(!!action.if);
                this.conditionalControl.setValue(action.if);
            }),
        );
        this.subscription.add(
            this.actionType$
                .pipe(startWith(null), distinctUntilChanged())
                .subscribe((newType) => {
                    if (!newType) {
                        return;
                    }
                    const options = this.config.defaults.actions[newType];
                    LoggerService.logStyled(
                        'ACTION-INPUT',
                        'Set new action type. Setting action to default',
                    );
                    this.actionControl.setValue({
                        type: newType,
                        options,
                    });
                }),
        );
        combineLatest([
            this.actionControl.valueChanges.pipe(
                startWith(this.actionControl.value),
                filter((a): a is NirbyAction => !!a),
            ),
            combineLatest([
                this.conditionalControl.valueChanges.pipe(
                    startWith(this.conditionalControl.value),
                    map((a): Conditional | undefined => a),
                ),
                this.enabledConditionControl.valueChanges.pipe(
                    startWith(this.enabledConditionControl.value),
                ),
            ]).pipe(
                map(([conditional, enabled]): Conditional | null => {
                    if (!enabled) {
                        return null;
                    }
                    return conditional ?? null;
                }),
                distinctUntilChanged(),
            ),
        ]).subscribe(([action, conditional]) => {
            const controller = this.controller;
            if (!controller) {
                return;
            }
            const block = controller.value;
            if (!block) {
                return;
            }
            controller.actions.updateAt('click', this.actionIndex, {
                ...action,
                if: conditional,
            });
        });
    }

    /**
     * Watches for the changes of this action index.
     * @param changes The changes.
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes['actionIndex']) {
            this.actionIndexController.next(this.actionIndex);
        }
    }

    /**
     * Unsubscribes from everything.
     */
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /**
     * Requests the editor to be closed.
     */
    close(): void {
        this.closeRequest.emit();
    }
}
