import {GetData, GetDataFactory, GetDataRaw, getDataRaw} from '../get-data';
import {Easing, EasingMethod} from './easing-methods';
import {LerpableType, LerpableTypeName} from './lerp';
import * as Lerp from './lerp-methods';
import {buildType, FunctionSegmentDescription, SegmentedFunctionDescription} from './segmented-function-description';


/**
 * A segment of a segmented function.
 */
export class FunctionSegment<T extends LerpableType> {
    /**
     * Constructor.
     * @param y0 The value of the function at `x = 0`.
     * @param y1 The value of the function at `x = xSpan`.
     * @param xSpan The span of the segment (the function is defined from `x = 0` to `x = xSpan`).
     * @param type The type of the function.
     * @param easingMethod The easing method to use.
     */
    constructor(
        public readonly y0: T,
        public readonly y1: T,
        public readonly xSpan: number,
        public readonly type: LerpableTypeName<T>,
        public readonly easingMethod: EasingMethod,
    ) {
    }

    /**
     * Creates a function segment from a description.
     * @param description The description of the function segment.
     * @param factory Factory for creating the type of the function segment.
     *
     * @returns The function segment.
     */
    static fromDescription<T extends LerpableType>(
        description: FunctionSegmentDescription<T>,
    ): FunctionSegment<T> {
        return new FunctionSegment<T>(
            buildType(description.y0, description.type),
            buildType(description.y1, description.type),
            description.xSpan,
            description.type,
            description.easingMethod,
        );
    }

    /**
     * Gets the value of the function at the given time.
     * @param t The time from 0 to `xSpan`.
     *
     * @returns The value of the function at the given time.
     */
    calc(t: number): T {
        if (t < 0) {
            return this.y0;
        }
        if (t > this.xSpan) {
            return this.y1;
        }
        const lerpFn = Lerp[this.type] as unknown as Lerp.LerpFunction<T>;
        return lerpFn(
            this.y0,
            this.y1,
            Easing[this.easingMethod](t / this.xSpan),
        );
    }

    /**
     * Creates a constant function segment that always returns the given value.
     * @param value The value of the function.
     * @param type The type of the function.
     * @param xSpan The span of the segment (the function is defined from `x = 0` to `x = xSpan`).
     *
     * @returns The function segment.
     */
    public static constant<T extends LerpableType>(value: T, type: LerpableTypeName<T>, xSpan = 0): FunctionSegment<T> {
        return new FunctionSegment(
            value,
            value,
            xSpan,
            type,
            'linear',
        );
    }

    /**
     * Gets the description of the function segment.
     *
     * @returns The description of the function segment.
     */
    describe(): FunctionSegmentDescription<T> {
        return {
            y0: getDataRaw(this.y0),
            y1: getDataRaw(this.y1),
            xSpan: this.xSpan,
            easingMethod: this.easingMethod,
            type: this.type,
        };
    }
}

interface FunctionSegmentOffset<T extends LerpableType> {
    offset: number;
    segment: FunctionSegment<T>;
}

export class SegmentedFunction<T extends LerpableType> {
    private readonly segments: ReadonlyArray<FunctionSegmentOffset<T>>;

    constructor(
        segments: FunctionSegment<T>[],
        private readonly offset = 0,
    ) {
        if (segments.length === 0) {
            throw new Error('Cannot create a segmented function with no segments.');
        }

        let segmentOffset = offset;
        const segmentsWithOffset: FunctionSegmentOffset<T>[] = [];
        for (const segment of segments) {
            segmentsWithOffset.push({
                offset: segmentOffset,
                segment,
            });
            segmentOffset += segment.xSpan;
        }
        this.segments = segmentsWithOffset;
    }

    static fromDescription<T extends GetData<LerpableType>>(
        description: SegmentedFunctionDescription<GetDataRaw<T>>,
        factory: GetDataFactory<T>,
    ): SegmentedFunction<GetDataRaw<T>>;

    static fromDescription<T extends LerpableType>(
        description: SegmentedFunctionDescription<T>,
    ): SegmentedFunction<T>;

    static fromDescription<T extends LerpableType>(
        description: SegmentedFunctionDescription<T>,
    ): SegmentedFunction<T> {
        return new SegmentedFunction(
            description.segments.map(d => FunctionSegment.fromDescription(d)),
            description.offset,
        );
    }

    private segmentFor(x: number): FunctionSegmentOffset<T> {
        let segment = this.segments[0];
        if (x < this.offset) {
            return {
                segment: FunctionSegment.constant(segment.segment.y0, segment.segment.type),
                offset: 0,
            };
        }
        for (const segmentWithOffset of this.segments) {
            if (x >= segmentWithOffset.offset) {
                segment = segmentWithOffset;
            } else {
                break;
            }
        }
        return segment;
    }

    public calc(x: number): T {
        const segment = this.segmentFor(x);
        return segment.segment.calc(x - segment.offset);
    }

    public describe(): SegmentedFunctionDescription<T> {
        return {
            segments: this.segments.map(segment => segment.segment.describe()),
            offset: this.offset,
        };
    }
}
