import {NIRBY_ACTION_SCHEMA, NirbyAction} from '@nirby/models/actions';
import {KeyframeTrack} from './base';
import {z} from 'zod';
import {ZodSchemaBuild} from '@nirby/store/models';
import {buildUploadedReferenceSchema} from '@nirby/store/proxy';
import {V1_SCENE_MIGRATOR} from '../scenes';
import {V1_PRIME_MIGRATOR} from '../v1-prime';


/**
 * A track for an action to be executed
 */
export type NirbyTrackAction = KeyframeTrack<'action', {
    action: NirbyAction | null;
}>

export const NIRBY_TRACK_ACTION_SCHEMA: ZodSchemaBuild<NirbyTrackAction> = z
    .object({
        type: z.enum(['keyframe']),
        contentType: z.enum(['action']),
        name: z.string(),
        sceneRef: buildUploadedReferenceSchema(() => V1_SCENE_MIGRATOR).nullable(),
        order: z.number(),
        usedBy: buildUploadedReferenceSchema(() => V1_PRIME_MIGRATOR).nullable(),
        properties: z.object({
            action: NIRBY_ACTION_SCHEMA.nullable(),
            startTimeMs: z.number(),
        }),
    })
    .describe('Nirby Track Action');
