import {z} from 'zod';

export type KnownColors =
    'light-gray' |
    'gray' |
    'brown' |
    'orange' |
    'yellow' |
    'green' |
    'blue' |
    'purple' |
    'pink' |
    'red';

export const KNOWN_COLORS_SCHEMA = z.enum(
    [
        'light-gray',
        'gray',
        'brown',
        'orange',
        'yellow',
        'green',
        'blue',
        'purple',
        'pink',
        'red',
    ],
);
