import {NirbyProductType, ProductDescription} from './analytics';
import {Buffer} from 'buffer';

import {decode, encode} from 'base-58';

export interface FullProductDescription {
    type: NirbyProductType;
    workspaceId: string;
    productId: string;
}


interface EncodedId<T> {
    get uniqueId(): string;

    get description(): T;
}

/**
 * An identifier for a Product. This assumes the workspace is already defined outside, as it is not part of the
 * {@link ProductId}.
 */
export class ProductId implements EncodedId<ProductDescription> {
    public static splitter = '$$';
    public static validProductTypes: NirbyProductType[] = [
        'pop',
        'prime',
    ];

    /**
     * Constructor.
     * @param id The product inner ID.
     * @param type The product type ({@link NirbyProductType}).
     */
    constructor(
        public readonly id: string,
        public readonly type: NirbyProductType,
    ) {
    }

    /**
     * Validates that an ID is valid.
     * @param uniqueId The ID to validate.
     *
     * @returns - True if the ID is valid, false otherwise.
     */
    public static validateUniqueId(uniqueId: string): boolean {
        return !!this.fromUniqueId(uniqueId);
    }

    /**
     * Checks if a product type is valid.
     * @param type The type to check.
     *
     * @returns - True if the type is valid, false otherwise.
     */
    public static isProductTypeValid(type: string): type is NirbyProductType {
        return this.validProductTypes.map(s => s as string).includes(type);
    }

    /**
     * Creates a product ID from a {@link ProductDescription} object.
     * @param description The description to create the product ID from.
     *
     * @returns - The created product ID.
     */
    public static fromDescription(description: ProductDescription): ProductId {
        return new ProductId(description.productId, description.type);
    }

    /**
     * Creates a product ID object from a unique ID string.
     * @param uniqueId The unique ID to create the product ID from.
     *
     * @returns - The created product ID.
     */
    public static fromUniqueId(uniqueId: string): ProductId | null {
        try {
            const decodedUniqueId = Buffer.from(decode(uniqueId)).toString('utf8');
            const split = decodedUniqueId.split(this.splitter);
            if (split.length !== 2) {
                return null;
            }
            const [type, id] = split;
            if (!this.isProductTypeValid(type)) {
                return null;
            }
            return new ProductId(id, type);
        } catch {
            return null;
        }
    }

    /**
     * Gets the product description of this ID.
     *
     * @returns - The product description.
     */
    public get description(): ProductDescription {
        return {
            productId: this.id,
            type: this.type,
        };
    }

    /**
     * Gets the unique ID of this product ID.
     */
    public get uniqueId(): string {
        return encode(Buffer.from(this.decodedUniqueId));
    }

    /**
     * Gets the decoded unique ID of this product ID.
     * @private
     */
    private get decodedUniqueId(): string {
        return `${this.type}${ProductId.splitter}${this.id}`;
    }
}


/**
 * A Product ID identifies a Nirby product in the system including a workspace.
 */
export class FullProductId implements EncodedId<FullProductDescription> {
    /**
     * Constructor
     * @param workspaceId The workspace ID.
     * @param id The product ID.
     * @param type The type of the product.
     */
    public constructor(
        public readonly workspaceId: string,
        public readonly id: string,
        public readonly type: NirbyProductType,
    ) {
    }

    /**
     * Get this ID as as {@link ProductId}. Note that this will lose the workspace ID.
     * @returns - The unique ID.
     */
    public asProductId(): ProductId {
        return new ProductId(this.id, this.type);
    }

    /**
     * Returns the decoded Unique ID.
     *
     * @private
     *
     * @returns The decoded Unique ID.
     */
    protected get decodedUniqueId(): string {
        return [this.workspaceId, this.type, this.id].join(ProductId.splitter);
    }

    /**
     * Return the product description.
     */
    get description(): FullProductDescription {
        return {
            workspaceId: this.workspaceId,
            productId: this.id,
            type: this.type,
        };
    }

    /**
     * Returns the Unique ID.
     */
    get uniqueId(): string {
        return encode(Buffer.from(this.decodedUniqueId));
    }

    /**
     * Creates a new ProductId instance from a Unique ID.
     * @param uniqueId The Unique ID.
     *
     * @returns The ProductId instance.
     */
    public static fromUniqueId(uniqueId: string): FullProductId | null {
        let decodedUniqueId: string;
        try {
            decodedUniqueId = Buffer.from(decode(uniqueId)).toString('utf8');
        } catch (e) {
            return null;
        }
        const split = decodedUniqueId.split(ProductId.splitter);
        if (split.length !== 3) {
            return null;
        }
        const [workspaceId, type, id] = split;
        if (!ProductId.isProductTypeValid(type)) {
            return null;
        }
        return new FullProductId(workspaceId, id, type);
    }

    /**
     * Gets the product ID of a full product ID.
     * @param fullProductId The full product ID.
     *
     * @returns The product ID.
     */
    static getProductId(fullProductId: string): string | null {
        return FullProductId.fromUniqueId(fullProductId)?.id ?? null;
    }

    /**
     * Gets the {@link FullProductId} with the same workspace ID but a different product ID.
     * @param type The type of the product.
     * @param id The product ID.
     *
     * @returns The full product ID.
     */
    withProductId(type: NirbyProductType, id: string): FullProductId {
        return new FullProductId(this.workspaceId, id, type);
    }
}
